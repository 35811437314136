import React from 'react';
import InputField from './InputField';
import TextArea from './TextArea';

type Props = {
  onSave: any;
  onClose: any;
  onDelete: any;
  onProductEdit: any;
  product: any;
};

export default class ProductEditor extends React.Component<Props, any> {
  save = evt => {
    evt.preventDefault();
    this.props.onSave();
  };

  close = evt => {
    evt.preventDefault();
    this.props.onClose();
  };

  render() {
    const product = this.props.product || {};

    return (
      <div className="p-3 pt-4 pb-4 mb-3">
        <h2 className="mb-3">{!!product ? 'Muokkaa palvelua' : 'Lisää palvelu'}</h2>

        {product.id && (
          <button
            className="btn btn-danger small mb-3"
            style={{ position: 'absolute', top: 15, right: 15 }}
            onClick={this.props.onDelete(product.id)}
          >
            Poista
          </button>
        )}

        <h2>Palvelun tyyppi</h2>
        <div className="form-group">
          <select
            className="form-control"
            id="exampleFormControlSelect1"
            value={product.type}
            onChange={this.props.onProductEdit('type')}
          >
            <option />
            <option value="0">Valokuvaus</option>
            <option value="1">Videokuvaus</option>
            <option value="2">Ilmakuvaus</option>
            <option value="4">Stailaus</option>
            ))
          </select>
        </div>

        <InputField
          key="name"
          id="name"
          label="Palvelun nimi"
          type="text"
          onChange={this.props.onProductEdit('name')}
          value={product.name || ''}
        />

        <InputField
          key="price"
          id="price"
          label="Palvelun hinta"
          type="text"
          onChange={this.props.onProductEdit('price')}
          value={product.price || ''}
        />

        <TextArea
          id="additionalinfo"
          label="Palvelun kuvaus"
          onChange={this.props.onProductEdit('description')}
          value={product.description}
        />

        <button onClick={this.save} className="btn btn-primary mr-3">
          Tallenna
        </button>
        <button onClick={this.close} className="btn btn-primary">
          Peruuta
        </button>
      </div>
    );
  }
}
