import React from 'react';
import { format } from 'date-fns';

export default class InvoiceItem extends React.Component<any, any> {
  render() {
    const { invoice, onClick } = this.props;

    const total = invoice.rows.reduce(function(a, b) {
      return parseFloat(a.amount || 0) + parseFloat(b.amount);
    }, 0);

    const totalWithTax = invoice.rows.reduce(function(a, b) {
      return parseFloat(a.amountWithTax || 0) + parseFloat(b.amountWithTax);
    }, 0);

    return (
      <div className="invoice mb-3" onClick={onClick}>
        <div className="row padding">
          <div className="col-12">
            <div className="row header mb-1">
              <div className="col-6 main-color font-weight-bold">Lasku</div>
              <div className="col-6 text-right">
                {format(new Date(invoice.createdAt), 'dd.MM.yyyy')}
              </div>
            </div>
            <div className="title">Lasku {invoice.assignment.name}</div>
            <div className="amount mb-2">
              {total}€ ({totalWithTax} € sis. ALV)
            </div>

            <div className="row header mb-1">
              <div className="col-6 main-color font-weight-bold">
                <span className="tag primary">{invoice.status}</span>
              </div>
              <div className="col-6 id text-right main-color">{invoice.id}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
