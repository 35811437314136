import React from 'react';
import classNames from 'classnames';
import {
  format,
  startOfWeek,
  endOfWeek,
  addDays,
  subDays,
  eachDayOfInterval,
  isWithinInterval,
  isSameDay,
  getDay,
  getHours,
  setHours,
  setMinutes,
  isBefore,
  addHours,
  parse,
  differenceInMinutes
} from 'date-fns';
import 'rc-time-picker/assets/index.css';
import { ReactComponent as Add } from '../../assets/icons/Add.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/RightArrow.svg';
import { ReactComponent as LeftArrowIcon } from '../../assets/icons/LeftArrow.svg';

type Props = {
  freeTimes: any[];
};

enum FinnishWeekDays {
  'Maanantai',
  'Tiistai',
  'Keskiviikko',
  'Torstai',
  'Perjantai',
  'Lauantai',
  'Sunnuntai'
}

/* Helpers */
const padLeft = (number, padding) => padding.substring(number.toString().length) + number;

const toTimeString = (hours, minutes) => `${padLeft(hours, '00')}:${padLeft(minutes, '00')}`;

const DAYS_OF_WEEK = ['MA', 'TI', 'KE', 'TO', 'PE', 'LA', 'SU'];

const Row = props => <div {...props} className={classNames('calendar__row', props.className)} />;

const Cell = props => <div {...props} className={classNames('calendar__cell', props.className)} />;

const TimeCell = props => (
  <Cell {...props} className={classNames('calendar__cell--time', props.className)} />
);

const HeaderCell = props => {
  const { day } = props;

  return (
    <Cell {...props} className={classNames('calendar__cell--day-of-week', props.className)}>
      {day >= 0 && <div className="calendar__cell--day-of-week__day">{DAYS_OF_WEEK[day]}</div>}
    </Cell>
  );
};

const AppointmentCell = props => {
  const { appointment } = props;
  let appointmentComponent;

  if (appointment) {
    const { blockSpan } = appointment;
    const height = 100 * blockSpan + '%';
    const borderPixels = blockSpan + 1 + 'px';
    const cssHeight = 'calc(' + height + ' + ' + borderPixels + ')';

    appointmentComponent = (
      <Appointment
        style={{ height: cssHeight }}
        appointment={appointment}
        onSelect={props.onSelect}
        selected={props.selected}
      />
    );
  }

  return (
    <Cell {...props} className={classNames('calendar__cell--appointment', props.className)}>
      {appointmentComponent}
    </Cell>
  );
};

const Appointment = props => {
  return (
    <div
      {...props}
      onClick={props.onSelect(props.appointment)}
      className={classNames('calendar__appointment', { selected: props.selected })}
    >
      <Add width="20" height="20" fill={props.selected ? '#fff' : '#4858e9'} />
    </div>
  );
};

export default class Calendar extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      timeblocks: [],
      starting: startOfWeek(new Date(), { weekStartsOn: 1 }),
      ending: endOfWeek(new Date(), { weekStartsOn: 1 }),
      selectedTimeSlot: '',
      selectedTime: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    this.normalizeTimeBlocks(nextProps.freeTimes);
  }

  componentDidMount() {
    this.normalizeTimeBlocks(this.props.freeTimes);
  }

  nextWeek = () => {
    this.setState(
      {
        starting: startOfWeek(addDays(this.state.starting, 7), { weekStartsOn: 1 }),
        ending: endOfWeek(addDays(this.state.ending, 7), { weekStartsOn: 1 })
      },
      () => {
        this.normalizeTimeBlocks(this.props.freeTimes);
      }
    );
  };

  previousWeek = () => {
    this.setState(
      {
        starting: startOfWeek(subDays(this.state.starting, 7), { weekStartsOn: 1 }),
        ending: endOfWeek(subDays(this.state.ending, 7), { weekStartsOn: 1 })
      },
      () => {
        this.normalizeTimeBlocks(this.props.freeTimes);
      }
    );
  };

  normalizeTimeBlocks = freeTimes => {
    const blockSize = 15;
    const timeBlocks = {};
    const eventBlocks = {};

    if (freeTimes) {
      const freeTimesWithinRange = freeTimes.filter(time => {
        return isWithinInterval(new Date(time.start), {
          start: this.state.starting,
          end: this.state.ending
        });
      });

      const days = eachDayOfInterval({ start: this.state.starting, end: this.state.ending });

      for (let day in days) {
        const freeTimesForDay = freeTimesWithinRange.filter(time => {
          return isSameDay(new Date(days[day]), new Date(time.start));
        });

        freeTimesForDay.forEach(ft => {
          const startTimeRaw = new Date(ft.start);
          const endTimeRaw = new Date(ft.end);

          const diff = differenceInMinutes(endTimeRaw, startTimeRaw);
          if (diff < this.props.duration) {
            return;
          }

          if (isBefore(startTimeRaw, addHours(new Date(), 24))) {
            return;
          }

          const startTime = toTimeString(startTimeRaw.getHours(), startTimeRaw.getMinutes());
          const endTime = toTimeString(endTimeRaw.getHours(), endTimeRaw.getMinutes());

          let blockSpan = 0;

          if (startTime === '00:00' && endTime === '00:00') {
            blockSpan = Math.ceil((24 * 60) / blockSize);
          } else {
            const startSplit = startTime.split(':');
            let hour = parseInt(startSplit[0]);
            let minutes = parseInt(startSplit[1]);
            let timeString = startTime;

            let fallBackCounter = 0;

            while (timeString !== endTime) {
              fallBackCounter++;
              blockSpan++;
              minutes += blockSize;

              if (minutes >= 60) {
                minutes = 0;
                hour += 1;
              }

              timeString = toTimeString(hour, minutes);

              if (fallBackCounter > 50) {
                break;
              }
            }
          }

          eventBlocks[startTime] = eventBlocks[startTime] || {};
          eventBlocks[startTime][day] = Object.assign({}, ft, {
            blockSpan
          });
        });
      }
    }

    for (let hour = 8; hour < 20; hour++) {
      for (let minutes = 0; minutes < 60; minutes += blockSize) {
        const timeString = toTimeString(hour, minutes);

        timeBlocks[timeString] = eventBlocks[timeString] || {};
      }
    }
    timeBlocks[toTimeString(20, 0)] = {};

    this.setState({
      timeBlocks
    });
  };

  selectTimeSlot = timeSlot => () => {
    const element = document.getElementById('time-selector');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    this.setState({
      selectedTimeSlot: timeSlot
    });
  };

  setTime = evt => {
    const time = parse(evt.target.value, 'HH:mm', new Date());

    const newTime = setHours(
      setMinutes(new Date(this.state.selectedTimeSlot.start), time.getMinutes()),
      time.getHours()
    );

    this.setState({
      selectedTime: newTime
    });
  };

  selectTime = evt => {
    evt.preventDefault();
    this.props.selectTime(this.state.selectedTime);
  };

  getDisabledHours = () => {
    const startHour = getHours(new Date(this.state.selectedTimeSlot.start));
    const endHour = getHours(new Date(this.state.selectedTimeSlot.end));

    let disabledHours = [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23];

    for (let hour of [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]) {
      if (hour < startHour || hour > endHour || hour + 1 > endHour) {
        disabledHours.push(hour);
      }
    }

    return disabledHours;
  };

  getAvailableHours = () => {
    const startHour = getHours(new Date(this.state.selectedTimeSlot.start));
    const endHour = getHours(new Date(this.state.selectedTimeSlot.end));

    let enabledHours: string[] = [];

    for (let hour of [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]) {
      if (hour >= startHour && hour - 1 <= endHour) {
        enabledHours.push(`${hour}:00`);
        enabledHours.push(`${hour}:15`);
        enabledHours.push(`${hour}:30`);
        enabledHours.push(`${hour}:45`);
      }
    }

    return enabledHours;
  };

  render() {
    const selectedTimeSlot = this.state.selectedTimeSlot.start;
    const selectedDateTimeSlot = new Date(selectedTimeSlot);
    const { selectedTime } = this.state;

    const availableHours = this.getAvailableHours();

    const rows: any[] = [];

    for (let time in this.state.timeBlocks) {
      const block = this.state.timeBlocks[time];

      rows.push(
        <Row key={time}>
          <TimeCell className="calendar__cell--time-col">{time}</TimeCell>
          <AppointmentCell className="calendar__cell--time-spacing" />
          <AppointmentCell
            onSelect={this.selectTimeSlot}
            appointment={block[0]}
            selected={block[0] && block[0].start === this.state.selectedTimeSlot.start}
          />
          <AppointmentCell
            onSelect={this.selectTimeSlot}
            appointment={block[1]}
            selected={block[1] && block[1].start === this.state.selectedTimeSlot.start}
          />
          <AppointmentCell
            onSelect={this.selectTimeSlot}
            appointment={block[2]}
            selected={block[2] && block[2].start === this.state.selectedTimeSlot.start}
          />
          <AppointmentCell
            onSelect={this.selectTimeSlot}
            appointment={block[3]}
            selected={block[3] && block[3].start === this.state.selectedTimeSlot.start}
          />
          <AppointmentCell
            onSelect={this.selectTimeSlot}
            appointment={block[4]}
            selected={block[4] && block[4].start === this.state.selectedTimeSlot.start}
          />
          <AppointmentCell
            onSelect={this.selectTimeSlot}
            appointment={block[5]}
            selected={block[5] && block[5].start === this.state.selectedTimeSlot.start}
            className="calendar__cell--weekend"
          />
          <AppointmentCell
            onSelect={this.selectTimeSlot}
            appointment={block[6]}
            selected={block[6] && block[6].start === this.state.selectedTimeSlot.start}
            className="calendar__cell--weekend"
          />
        </Row>
      );
    }

    return (
      <div className="calendar-container">
        <div className="calendar">
          <div className="week-selector flex-center">
            <span onClick={this.previousWeek} className="arrow">
              <LeftArrowIcon className="icon" width="20" height="20" />
            </span>
            {format(this.state.starting, 'dd.MM.yyyy')} - {format(this.state.ending, 'dd.MM.yyyy')}
            <span onClick={this.nextWeek} className="arrow">
              <RightArrowIcon className="icon" width="20" height="20" />
            </span>
          </div>
          <Row>
            <HeaderCell className="calendar__cell--time-col" />
            <HeaderCell day={0} />
            <HeaderCell day={1} />
            <HeaderCell day={2} />
            <HeaderCell day={3} />
            <HeaderCell day={4} />
            <HeaderCell day={5} />
            <HeaderCell day={6} />
          </Row>

          <div className="calendar__body">{rows}</div>
        </div>
        {selectedTimeSlot && (
          <div id="time-selector" className="date-selector text-center">
            <div className="title">
              {FinnishWeekDays[getDay(selectedDateTimeSlot) - 1]}{' '}
              {format(selectedDateTimeSlot, 'dd.MM.yyyy')}
            </div>
            Valitse tarkka ajankohta
            <select
              onChange={this.setTime}
              className="form-control mb-3"
              style={{ maxWidth: 250, margin: 'auto' }}
            >
              <option />
              {availableHours.map(h => (
                <option>{h}</option>
              ))}
            </select>
            <button
              onClick={this.selectTime}
              disabled={!selectedTime}
              className="btn btn-primary mb-3"
            >
              Valitse aika
            </button>
          </div>
        )}
      </div>
    );
  }
}
