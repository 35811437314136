import React from 'react';
import { observer } from 'mobx-react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import AlertStore from '../../stores/Alert.store';
import { Invoice } from '../../Api';
import DatePicker from 'react-date-picker';
import InputField from '../../components/InputField';

type RouteParams = {
  id: string;
};

type Props = RouteComponentProps<RouteParams>;

type State = {
  invoice: any;
  openRowEditor: boolean;
  loading: boolean;
  newRow: {
    description: string;
    amount?: number;
  };
  editingRow?: number;
};

@observer
class ViewInvoice extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      invoice: undefined,
      newRow: {
        description: '',
        amount: 0
      },
      openRowEditor: false,
      editingRow: undefined,
      loading: true
    };
  }

  async componentDidMount() {
    try {
      const invoice = await Invoice.get(this.props.match.params.id);

      this.setState({
        invoice,
        loading: false
      });
    } catch (e) {}
  }

  handleChange = property => async evt => {
    const value = evt.target.value;

    this.setState(prevState => ({
      invoice: {
        ...prevState.invoice,
        [property]: value
      }
    }));
  };

  handleDueDateChange = date => {
    date.setHours(date.getHours() + 10);
    this.setState(prevState => ({
      invoice: {
        ...prevState.invoice,
        dueDate: date.toISOString()
      }
    }));
  };

  handleRowChange = (rowId, property) => async evt => {
    evt.preventDefault();

    let rows = this.state.invoice.rows;
    const index = rows.findIndex(r => r.id === rowId || r.temporaryId === rowId);

    if (index > -1) {
      rows[index][property] = evt.target.value;

      this.setState(prevState => ({
        invoice: {
          ...prevState.invoice,
          rows
        }
      }));
    }
  };

  editRow = id => evt => {
    this.setState({
      editingRow: id
    });
  };

  finishEditingRow = evt => {
    this.setState({
      editingRow: undefined
    });
  };

  saveRow = () => {
    // Temporary ID which is used for non-saved rows. Rows are given real ID upon saving to database
    const id = Date.now();

    this.setState(prevState => ({
      invoice: {
        ...prevState.invoice,
        rows: [
          ...prevState.invoice.rows,
          {
            temporaryId: id,
            description: '',
            amount: 0,
            taxPercentage: 24
          }
        ]
      },
      editingRow: id
    }));
  };

  deleteRow = (rowId: number) => evt => {
    evt.preventDefault();

    let rows = this.state.invoice.rows;
    const index = rows.findIndex(r => r.id === rowId || r.temporaryId === rowId);

    if (index > -1) {
      rows.splice(index, 1);

      this.setState(prevState => ({
        invoice: {
          ...prevState.invoice,
          rows
        },
        editingRow: undefined
      }));
    }
  };

  save = async evt => {
    evt.preventDefault();
    try {
      await Invoice.update(this.state.invoice);
      AlertStore.addAlert({
        type: 'success',
        message: 'Lasku päivitetty'
      });
    } catch (e) {}
  };

  renderRow = row => {
    const id = row.id || row.temporaryId;

    if (this.state.editingRow === id) {
      return (
        <tr key={id} className="edit-invoice">
          <td>
            <input
              className="description"
              type="text"
              step="0.01"
              value={row.description}
              onChange={this.handleRowChange(id, 'description')}
              onBlur={this.finishEditingRow}
            />

            <button
              className="btn btn-primary extra-small delete-button"
              onClick={this.deleteRow(id)}
            >
              Poista
            </button>
          </td>
          <td>
            <input
              className="amount"
              type="number"
              step="0.01"
              value={row.amount}
              onChange={this.handleRowChange(id, 'amount')}
            />
          </td>
          <td>{this.renderPriceWithTax(row)}</td>
        </tr>
      );
    }
    return (
      <tr key={row.id || row.temporaryId} onClick={this.editRow(id)}>
        <td>{row.description}</td>
        <td>{row.amount} €</td>
        <td>{this.renderPriceWithTax(row)}</td>
      </tr>
    );
  };

  renderPriceWithTax = row => {
    return (
      <span>
        {(row.amount * 1.24).toFixed(2)}€ <small>(24%)</small>
      </span>
    );
  };

  render() {
    const { invoice, loading, editingRow } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        <div className="statusbar">
          <div>
            <Link to="/laskut">&laquo; Takaisin</Link>
          </div>
          <div>
            <span className="tag small success">{invoice.status}</span>
          </div>
        </div>
        <div className="invoice-single container mt-5">
          <h1 className="mb-5 d-block">Lasku {invoice.assignment.name}</h1>

          <div className="mb-5">
            <table className="table">
              <thead>
                <tr>
                  <th>Tuote</th>
                  <th style={{ width: '20%' }}>Hinta</th>
                  <th style={{ width: '20%' }}>Hinta + alv</th>
                </tr>
              </thead>
              <tbody>{invoice.rows.map(row => this.renderRow(row))}</tbody>
            </table>
            <div className="row-controls mb-3">
              {editingRow && (
                <button
                  className="btn btn-primary extra-small mt-3 mr-3"
                  onClick={this.finishEditingRow}
                >
                  <div className="pl-2 pr-2">OK</div>
                </button>
              )}

              <button className="btn btn-primary extra-small mt-3" onClick={this.saveRow}>
                + Uusi rivi
              </button>
            </div>

            <hr />

            <div className="mb-3">
              <label className="label">Eräpäivä</label>
              <DatePicker
                calendarIcon={null}
                clearIcon={null}
                value={new Date(invoice.dueDate)}
                onChange={this.handleDueDateChange}
                className=""
              />
            </div>
            <div>
              <InputField
                key="referenceNumber"
                id="referenceNumber"
                label="Viitenumero"
                type="text"
                onChange={undefined}
                value={invoice.referenceNumber || ''}
                disabled
              />
            </div>
          </div>

          <button onClick={this.save} className="btn btn-primary mr-3">
            Tallenna luonnos
          </button>
          <button className="btn btn-primary">Lähetä lasku</button>
        </div>
      </>
    );
  }
}

export default withRouter(ViewInvoice);
