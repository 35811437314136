import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import hero from '../assets/img/hero.jpg';
import AuthStore from '../stores/Auth.store';
import UserStore from '../stores/User.store';

@observer
class IndexView extends React.Component<RouteComponentProps> {
  render() {
    const notAuthenticatedContent = (
      <div className="login-box text-center d-flex justify-content-center align-items-center flex-column p-5">
        <p className="mb-5">Kirjaudu sisään</p>
        <Link to={`/kirjaudu`}>
          <button className="btn btn-primary mb-3">Kiinteistövälittäjä</button>
        </Link>
        <Link to={`/kirjaudu-palveluntuottaja`}>
          <button className="btn btn-primary mb-3">Palveluntuottaja</button>
        </Link>
      </div>
    );

    const agentContent = (
      <div className="login-box text-center d-flex justify-content-center align-items-center flex-column p-5">
        <h3 className="mb-5">Tervetuloa Bondriin</h3>
        <Link to={`/toimeksianto/uusi`}>
          <button className="btn btn-primary mb-3" style={{ minWidth: 265 }}>
            Uusi toimeksianto
          </button>
        </Link>
        <Link to={`/omat-toimeksiannot`}>
          <button className="btn btn-primary mb-3" style={{ minWidth: 265 }}>
            Omat toimeksiannot
          </button>
        </Link>
      </div>
    );

    const providerContent = (
      <div className="login-box text-center d-flex justify-content-center align-items-center flex-column p-5">
        <h3 className="mb-5">Tervetuloa Bondriin</h3>
        <Link to={`/omat-toimeksiannot`}>
          <button className="btn btn-primary mb-3" style={{ minWidth: 265 }}>
            Omat toimeksiannot
          </button>
        </Link>
      </div>
    );

    const authenticatedContent = UserStore.isProvider ? providerContent : agentContent;

    return (
      <div>
        <div id="main">
          <div className="hero" style={{ backgroundImage: `url(${hero})` }}>
            <div className="hero-content container">
              <div className="row">
                <div className="col-12 col-sm-12 col-lg-4 d-flex align-items-center">
                  <p className="slogan">Kiinteistömarkkinoinnin kohtaamispaikka. Bondr.</p>
                </div>
                <div className="col-12 col-sm-12 col-lg-4">
                  {AuthStore.isAuthenticated ? authenticatedContent : notAuthenticatedContent}
                </div>
                <div className="col-12 col-sm-12 col-lg-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(IndexView);
