import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import OrganizationStore from '../../stores/Organization.store';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/RightArrow.svg';
import { Organization } from '../../Api';
import InputField from '../../components/InputField';
import AlertStore from '../../stores/Alert.store';

interface RouteParams {
  id: string;
}

@observer
class EditOrganization extends React.Component<RouteComponentProps<RouteParams>, any> {
  constructor(props) {
    super(props);

    this.state = {
      organization: {},
      loading: true
    };
  }

  async componentDidMount() {
    try {
      const organization = await Organization.findById(Number(this.props.match.params.id));

      this.setState({
        organization,
        loading: false
      });
    } catch (e) {}
  }

  handleChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      organization: {
        ...prevState.organization,
        [key]: value
      }
    }));
  };

  saveOrganization = async () => {
    try {
      const { organization } = this.state;
      await Organization.update(organization);

      AlertStore.addAlert({
        type: 'success',
        message: `Organisaation tiedot päivitetty`,
        timeout: 10000
      });

      this.props.history.push(`/organisaatiot/${this.props.match.params.id}`);
    } catch (e) {}
  };

  render() {
    const { organization } = this.state;

    return (
      <div className="container mt-5">
        <h1 className="mb-5 d-block">Muokkaa organisaation {organization.name} tietoja</h1>

        <InputField
          id="businessId"
          label="Y-tunnus"
          type="text"
          onChange={this.handleChange('businessId')}
          value={organization.businessId || ''}
        />

        <InputField
          id="address"
          label="Osoite"
          type="text"
          onChange={this.handleChange('address')}
          value={organization.address || ''}
        />

        <InputField
          id="zipcode"
          label="Postinumero"
          type="text"
          onChange={this.handleChange('zipcode')}
          value={organization.zipcode || ''}
        />

        <InputField
          id="city"
          label="Postitoimipaikka"
          type="text"
          onChange={this.handleChange('city')}
          value={organization.city || ''}
        />

        <button onClick={this.saveOrganization} className="btn btn-primary mr-3">
          Tallenna
        </button>

        <Link to={`/organisaatiot/${this.props.match.params.id}`}>
          <button className="btn btn-primary mr-3">Peruuta</button>
        </Link>
      </div>
    );
  }
}

export default withRouter(EditOrganization);
