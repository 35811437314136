import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { Auth, User } from '../../Api';
import AlertStore from '../../stores/Alert.store';
import AuthStore from '../../stores/Auth.store';
import UserStore from '../../stores/User.store';
import InputField from '../../components/InputField';
import ProductEditor from '../../components/ProductEditor';
import ProductBox from '../../components/ProductBox';
import { ProductType } from '../../util/enum';
import RadioButton from '../../components/input/RadioButton';
import Checkbox from '../../components/input/Checkbox';
import ModalContainer from '../ModalContainer';
import { Product } from '../../typings/Product';

interface RouteParams {
  id: string;
}

interface State {
  loading: boolean;
  user: any;
  userProducts: Product[];
  calendars: any[];
  openProductEditor: boolean;
  productEdited: any;
  passwordReset: {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
  };
  newAvatar: {
    file: string | ArrayBuffer | null;
    filename: string;
  };
  showModals: {
    resetPassword: boolean;
    changeAvatar: boolean;
  };
}

@observer
class EditUser extends React.Component<RouteComponentProps<RouteParams>, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      user: {},
      userProducts: [],
      calendars: [],
      openProductEditor: false,
      productEdited: null,
      passwordReset: {
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: ''
      },
      newAvatar: {
        file: '',
        filename: ''
      },
      showModals: {
        resetPassword: false,
        changeAvatar: false
      }
    };
  }

  async componentDidMount() {
    this.refresh();
  }

  async refresh() {
    const id = this.props.match.params.id || UserStore.userData.id;

    const user = await User.findById(id);
    const calendars = user.role === 'provider' ? await User.getCalendars(id) : [];

    if (user.role === 'provider' && user.settings && !user.settings.workingHours) {
      user.settings.workingHours = this.getDefaultWorkingHours();
    }

    this.setState({
      loading: false,
      user,
      calendars
    });
  }

  closeModal = name => evt => {
    this.setState(prevState => ({
      showModals: {
        ...prevState.showModals,
        [name]: false
      }
    }));
  };

  openModal = name => evt => {
    this.setState(prevState => ({
      showModals: {
        ...prevState.showModals,
        [name]: true
      }
    }));
  };

  handleChange = key => evt => {
    const value = evt.target.value;
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [key]: value
      }
    }));
  };

  handleSettingsChange = (key: string, formatNumber?: boolean) => evt => {
    const value = evt.target.value;
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        settings: {
          ...prevState.user.settings,
          [key]: formatNumber ? Number(value) : value
        }
      }
    }));
  };

  handlePasswordChange = key => evt => {
    const value = evt.target.value;
    this.setState(prevState => ({
      passwordReset: {
        ...prevState.passwordReset,
        [key]: value
      }
    }));
  };

  handleWorkingHourToggle = key => evt => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        settings: {
          ...prevState.user.settings,
          workingHours: {
            ...prevState.user.settings.workingHours,
            [key]: {
              ...prevState.user.settings.workingHours[key],
              enabled: !prevState.user.settings.workingHours[key].enabled
            }
          }
        }
      }
    }));
  };

  handleWorkingHourChange = (type: 'starting' | 'ending', weekday: string) => evt => {
    const value = evt.target.value;
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        settings: {
          ...prevState.user.settings,
          workingHours: {
            ...prevState.user.settings.workingHours,
            [weekday]: {
              ...prevState.user.settings.workingHours[weekday],
              [type]: value
            }
          }
        }
      }
    }));
  };

  handleUserActiveToggle = async evt => {
    evt.preventDefault();
    try {
      const activeStatus = !this.state.user.active;
      await User.toggleActive(this.state.user.id, activeStatus);
      this.refresh();

      AlertStore.addAlert({
        id: 'user-activated',
        type: 'success',
        message: `Käyttäjä ${activeStatus ? 'aktivoitu' : 'deaktivoitu'}`
      });
    } catch (e) {}
  };

  handleImageUpload = async (acceptedFiles, rejectedFiles, evt) => {
    try {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.addEventListener('loadend', async () => {
        this.setState({
          newAvatar: {
            file: reader.result,
            filename: file.name
          }
        });
      });
    } catch (e) {
      AlertStore.addAlert({
        type: 'danger',
        message: `Virhe kuvan lisäämisessä: ${e}`
      });
    }
  };

  getDefaultWorkingHours = () => {
    return {
      monday: {
        enabled: false,
        starting: '08:00',
        ending: '16:00'
      },
      tuesday: {
        enabled: false,
        starting: '08:00',
        ending: '16:00'
      },
      wednesday: {
        enabled: false,
        starting: '08:00',
        ending: '16:00'
      },
      thursday: {
        enabled: false,
        starting: '08:00',
        ending: '16:00'
      },
      friday: {
        enabled: false,
        starting: '08:00',
        ending: '16:00'
      },
      saturday: {
        enabled: false,
        starting: '08:00',
        ending: '16:00'
      },
      sunday: {
        enabled: false,
        starting: '08:00',
        ending: '16:00'
      }
    };
  };

  onSubmit = async evt => {
    evt.preventDefault();

    try {
      const user: any = await User.update(this.state.user.id, this.state.user);

      if (user.settings && !!user.settings.calendarId) {
        AlertStore.clearAlert('alert-nocalendar');
      }

      AlertStore.addAlert({
        id: 'profile-updated',
        type: 'success',
        message: 'Käyttäjätiedot päivitetty'
      });

      AuthStore.refreshToken();
      UserStore.updateUserData();

      this.props.history.push(`/`);
    } catch (e) {
      AlertStore.addAlert({
        id: 'profile-updated-error',
        type: 'danger',
        message: 'Virhe käyttäjätietojen päivityksessä'
      });
    }
  };

  savePassword = async () => {
    try {
      const { oldPassword, newPassword, newPasswordRepeat } = this.state.passwordReset;

      this.setState({
        loading: true
      });

      await User.setPasswordWithoutToken(oldPassword, newPassword, newPasswordRepeat);

      this.setState({
        passwordReset: {
          oldPassword: '',
          newPassword: '',
          newPasswordRepeat: ''
        },
        showModals: {
          resetPassword: false,
          changeAvatar: false
        },
        loading: false
      });

      AlertStore.addAlert({
        id: 'password-updated',
        type: 'success',
        message: 'Salasana vaihdettu'
      });
    } catch (e) {
      AlertStore.addAlert({
        id: 'password-updated-error',
        type: 'danger',
        message: 'Virhe salasanan vaihtamisessa: ' + e
      });
    }
  };

  saveAvatar = async () => {
    try {
      const { filename, file } = this.state.newAvatar;

      await User.setAvatar(filename, file as ArrayBuffer);

      this.setState({
        newAvatar: {
          file: '',
          filename: ''
        },
        showModals: {
          resetPassword: false,
          changeAvatar: false
        },
        loading: false
      });

      this.refresh();

      AlertStore.addAlert({
        id: 'avatar-updated',
        type: 'success',
        message: 'Profiilikuva vaihdettu'
      });
    } catch (e) {
      AlertStore.addAlert({
        id: 'avatar-updated-error',
        type: 'danger',
        message: 'Virhe profiilikuvan vaihtamisessa: ' + e
      });
    }
  };

  newProduct = () => {
    this.setState(prevState => ({
      openProductEditor: !this.state.openProductEditor,
      productEdited: null
    }));
  };

  editProduct = product => evt => {
    this.setState({
      openProductEditor: true,
      productEdited: product
    });
  };

  resetProductEditor = () => {
    this.setState({
      openProductEditor: false,
      productEdited: null
    });
  };

  handleProductEditing = key => evt => {
    const value = evt.target.value;

    this.setState(prevState => ({
      productEdited: {
        ...prevState.productEdited,
        [key]: value
      }
    }));
  };

  handlePasswordReset = async evt => {
    evt.preventDefault();

    const { email } = this.state.user;

    try {
      await Auth.resetPassword(email);

      AlertStore.addAlert({
        type: 'success',
        message: `Salasanan asetusviesti lähetetty sähköpostiosoitteeseen ${email}`,
        timeout: 10000
      });
    } catch (e) {
      console.log(e);
    }
  };

  saveProduct = async () => {
    const product = {
      id: this.state.productEdited.id,
      name: this.state.productEdited.name,
      price: Number(this.state.productEdited.price),
      description: this.state.productEdited.description,
      type: Number(this.state.productEdited.type)
    };

    let newProductArray;

    if (product.id) {
      newProductArray = this.state.user.products.map(p => {
        if (p.id === product.id) {
          return product;
        }
        return p;
      });
    } else {
      newProductArray = [...this.state.user.products, product];
    }

    try {
      await User.updateProduct(product);

      this.setState({
        user: {
          ...this.state.user,
          products: newProductArray
        }
      });

      this.resetProductEditor();

      AlertStore.addAlert({
        type: 'success',
        message: 'Tuote päivitetty'
      });
    } catch (e) {}
  };

  deleteProduct = (productId: number) => async evt => {
    evt.preventDefault();

    try {
      await User.deleteProduct(productId);

      this.setState({
        user: {
          ...this.state.user,
          products: this.state.user.products.filter(p => p.id !== productId)
        }
      });

      this.resetProductEditor();

      AlertStore.addAlert({
        type: 'success',
        message: 'Tuote poistettu'
      });
    } catch (e) {}
  };

  closeProductEditor = () => {
    this.setState({
      openProductEditor: false
    });
  };

  getProductsByType = type => {
    return this.state.user.products.filter(product => product.type === type);
  };

  getWorkingHourList = () => {
    return (
      <>
        <option value="08:00">08:00</option>
        <option value="09:00">09:00</option>
        <option value="10:00">10:00</option>
        <option value="11:00">11:00</option>
        <option value="12:00">12:00</option>
        <option value="13:00">13:00</option>
        <option value="14:00">14:00</option>
        <option value="15:00">15:00</option>
        <option value="16:00">16:00</option>
        <option value="17:00">17:00</option>
        <option value="18:00">18:00</option>
        <option value="19:00">19:00</option>
        <option value="20:00">20:00</option>
      </>
    );
  };

  render() {
    const { user, calendars, passwordReset, newAvatar, loading } = this.state;

    const isOwnProfile =
      !this.props.match.params.id || UserStore.userData.id === this.props.match.params.id;

    if (loading) {
      return (
        <div className="container mt-5">
          <h2>Ladataan</h2>
        </div>
      );
    }

    const isSubordinate =
      user.organizations.filter(o => UserStore.userData.adminOf.map(o => o.id).includes(o.id))
        .length > 0 || UserStore.isAdmin;

    const weekdays = {
      monday: 'Maanantai',
      tuesday: 'Tiistai',
      wednesday: 'Keskiviikko',
      thursday: 'Torstai',
      friday: 'Perjantai',
      saturday: 'Lauantai',
      sunday: 'Sunnuntai'
    };

    console.log(user);

    return (
      <div className="container mt-5">
        <ModalContainer
          title="Salasanan vaihto"
          isOpen={this.state.showModals.resetPassword}
          handleClose={this.closeModal('resetPassword')}
        >
          <InputField
            id="old-password"
            label="Vanha salasana"
            type="password"
            onChange={this.handlePasswordChange('oldPassword')}
            value={passwordReset.oldPassword || ''}
          />
          <InputField
            id="new-password"
            label="Uusi salasana"
            type="password"
            onChange={this.handlePasswordChange('newPassword')}
            value={passwordReset.newPassword || ''}
          />
          <InputField
            id="new-password-repeat"
            label="Toista uusi salasana"
            type="password"
            onChange={this.handlePasswordChange('newPasswordRepeat')}
            value={passwordReset.newPasswordRepeat || ''}
          />

          <div className="text-center mt-5">
            <button className="btn btn-primary" onClick={this.savePassword} disabled={loading}>
              Vaihda salasana
            </button>
          </div>
        </ModalContainer>

        <ModalContainer
          title="Profiilikuvan vaihto"
          isOpen={this.state.showModals.changeAvatar}
          handleClose={this.closeModal('changeAvatar')}
        >
          <Dropzone
            accept="image/jpg, image/jpeg, image/png"
            minSize={0}
            maxSize={5242880}
            onDrop={this.handleImageUpload}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => {
              return (
                <>
                  <section
                    className={classNames('dropzone mt-3 mb-3 px-4', { active: isDragActive })}
                  >
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {!newAvatar.file && <span>Klikkaa tai pudota uusi kuva tähän</span>}

                      {newAvatar.file && (
                        <div>
                          <div
                            style={{ backgroundImage: `url(${newAvatar.file})` }}
                            className="profile-pic"
                          />
                        </div>
                      )}
                      {isDragReject && (
                        <span className="d-block error">
                          Virhe: Kuvan täytyy olla jpg- tai png-muodossa
                        </span>
                      )}
                    </div>
                  </section>
                  {newAvatar.file && <div className="text-center">{newAvatar.filename}</div>}
                </>
              );
            }}
          </Dropzone>
          <div className="text-center mt-5">
            <button
              className="btn btn-primary"
              onClick={this.saveAvatar}
              disabled={!newAvatar || loading}
            >
              Vaihda profiilikuva
            </button>
          </div>
        </ModalContainer>
        <h1 className="mb-5 d-block">
          {isOwnProfile ? 'Oma tili' : `Käyttäjä ${user.firstName} ${user.lastName}`}
        </h1>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-4">
              <div
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_API_URL}/images/${user.picture})`
                }}
                className="profile-pic"
              />
            </div>
            <div className="col-6 col-lg-4 profile-card">
              <div className="profile-text mb-3">
                <small>Nimi</small>
                <br />
                {`${user.firstName} ${user.lastName}`}
              </div>

              <div className="profile-text">
                <small>Käyttäjätunnus</small>
                <br />
                {`${user.email}`}
              </div>
            </div>
            <div className="col-12 col-lg-3 pt-2">
              <div className="flex-between-sm">
                <div className="link mb-1" onClick={this.openModal('changeAvatar')}>
                  Vaihda profiilikuva
                </div>
                <div className="link mb-1" onClick={this.openModal('resetPassword')}>
                  Vaihda salasana
                </div>
              </div>

              {UserStore.isAdmin && (
                <>
                  <div className="link mb-1" onClick={this.handleUserActiveToggle}>
                    {user.active ? 'Deaktivoi käyttäjä' : 'Aktivoi käyttäjä'}
                  </div>
                </>
              )}

              {isSubordinate && (
                <div className="link mb-1" onClick={this.handlePasswordReset}>
                  Lähetä salasanan asettamislinkki
                </div>
              )}
            </div>
          </div>

          <hr />

          {user.role === 'agent' && (
            <>
              {isSubordinate && (
                <>
                  <h2 className="mb-3">Käyttöoikeudet</h2>
                  <p>Salli kuvaajien hintojen näkeminen</p>
                  <div className="radiobutton-group">
                    <div className="radiobutton">
                      <RadioButton
                        id="view-prices-1"
                        name="view-prices[]"
                        value="1"
                        onChange={this.handleSettingsChange('canViewPrices', true)}
                        checked={user.settings && user.settings.canViewPrices === 1}
                      />
                      <label className="control-label" htmlFor="view-prices-1">
                        Kyllä
                      </label>
                    </div>
                    <div className="radiobutton">
                      <RadioButton
                        id="view-prices-0"
                        name="view-prices[]"
                        value="0"
                        onChange={this.handleSettingsChange('canViewPrices', true)}
                        checked={user.settings && user.settings.canViewPrices === 0}
                      />
                      <label className="control-label" htmlFor="view-prices-0">
                        Ei
                      </label>
                    </div>
                  </div>

                  <p>Salli tilaaminen muilta, kuin yrityksen suosikeilta</p>
                  <div className="radiobutton-group">
                    <div className="radiobutton">
                      <RadioButton
                        id="only-favs-1"
                        name="only-favs[]"
                        value="1"
                        onChange={this.handleSettingsChange('canOrderFromAnyone', true)}
                        checked={user.settings && user.settings.canOrderFromAnyone === 1}
                      />
                      <label className="control-label" htmlFor="only-favs-1">
                        Kyllä
                      </label>
                    </div>
                    <div className="radiobutton">
                      <RadioButton
                        id="only-favs-0"
                        name="only-favs[]"
                        value="0"
                        onChange={this.handleSettingsChange('canOrderFromAnyone', true)}
                        checked={user.settings && user.settings.canOrderFromAnyone === 0}
                      />
                      <label className="control-label" htmlFor="only-favs-0">
                        Ei
                      </label>
                    </div>
                  </div>

                  <p>Salli yrityksen suosikkilistan muokkaaminen</p>
                  <div className="radiobutton-group">
                    <div className="radiobutton">
                      <RadioButton
                        id="edit-favs-1"
                        name="edit-favs[]"
                        value="1"
                        onChange={this.handleSettingsChange('canEditFavourites', true)}
                        checked={user.settings && user.settings.canEditFavourites === 1}
                      />
                      <label className="control-label" htmlFor="edit-favs-1">
                        Kyllä
                      </label>
                    </div>
                    <div className="radiobutton">
                      <RadioButton
                        id="edit-favs-0"
                        name="edit-favs[]"
                        value="0"
                        onChange={this.handleSettingsChange('canEditFavourites', true)}
                        checked={user.settings && user.settings.canEditFavourites === 0}
                      />
                      <label className="control-label" htmlFor="edit-favs-0">
                        Ei
                      </label>
                    </div>
                  </div>
                  <hr />
                </>
              )}
            </>
          )}

          {(user.role === 'agent' || user.role === 'admin') && (
            <>
              <h2 className="mb-3">Perustiedot</h2>
              <InputField
                key="phone"
                id="phone"
                label="Puhelinnumero"
                type="tel"
                onChange={this.handleChange('phone')}
                value={user.phone || ''}
              />
            </>
          )}

          {user.role === 'provider' && (
            <>
              <h2>Ajanvarauksissa käytettävä kalenteri</h2>
              <div className="form-group">
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={user.settings.calendarId}
                  onChange={this.handleSettingsChange('calendarId')}
                >
                  <option />
                  {calendars.map(cal => (
                    <option key={cal.id} value={cal.id}>
                      {cal.summary}
                    </option>
                  ))}
                </select>
              </div>

              <h2>Työajat</h2>
              <div className="form-group">
                {Object.keys(weekdays).map(key => (
                  <div key={key} className="mb-4">
                    <Checkbox
                      id={key}
                      disabled={false}
                      onChange={this.handleWorkingHourToggle(key)}
                      value={key}
                      checked={user.settings.workingHours[key].enabled}
                    />
                    {weekdays[key]}

                    {user.settings.workingHours[key].enabled && (
                      <div className="flex-center mt-1">
                        <select
                          className="form-control"
                          id=""
                          value={user.settings.workingHours[key].starting}
                          onChange={this.handleWorkingHourChange('starting', key)}
                        >
                          {this.getWorkingHourList()}
                        </select>
                        <div className="ml-2 mr-2">–</div>
                        <select
                          className="form-control"
                          id=""
                          value={user.settings.workingHours[key].ending}
                          onChange={this.handleWorkingHourChange('ending', key)}
                        >
                          {this.getWorkingHourList()}
                        </select>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <h2 className="mb-3">Perustiedot</h2>
              <div className="row mb-4">
                <div className="col-12">
                  <InputField
                    key="phone"
                    id="phone"
                    label="Puhelinnumero"
                    type="tel"
                    onChange={this.handleChange('phone')}
                    value={user.phone || ''}
                  />
                </div>
              </div>

              <h2 className="mb-3">Osoite</h2>

              <div className="row mb-4">
                <div className="col-12">
                  <InputField
                    key="address"
                    id="address"
                    label="Katuosoite"
                    type="text"
                    onChange={this.handleChange('address')}
                    value={user.address || ''}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    key="zipcode"
                    id="zipcode"
                    label="Postinumero"
                    type="text"
                    onChange={this.handleChange('zipcode')}
                    value={user.zipcode || ''}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    key="city"
                    id="city"
                    label="Kaupunki"
                    type="text"
                    onChange={this.handleChange('city')}
                    value={user.city || ''}
                  />
                </div>
              </div>

              <h2 className="mb-3">Yrityksen tiedot</h2>
              <div className="row mb-4">
                <div className="col-12">
                  <InputField
                    key="businessname"
                    id="businessname"
                    label="Yrityksen nimi"
                    type="text"
                    onChange={this.handleChange('businessname')}
                    value={user.businessname || ''}
                  />
                </div>
                <div className="col-12">
                  <InputField
                    key="businessid"
                    id="businessid"
                    label="Y-tunnus"
                    type="text"
                    onChange={this.handleChange('businessid')}
                    value={user.businessid || ''}
                  />
                </div>
              </div>

              <h2 className="mb-3">Palvelut</h2>

              <span onClick={this.newProduct} className="link d-block mb-3">
                Lisää palvelu
              </span>

              <ProductBox
                title="Valokuvaus"
                products={this.getProductsByType(ProductType.Photography)}
                onProductEdit={this.editProduct}
              />

              <ProductBox
                title="Videokuvaus"
                products={this.getProductsByType(ProductType.Video)}
                onProductEdit={this.editProduct}
              />

              <ProductBox
                title="Ilmakuvaus"
                products={this.getProductsByType(ProductType.AirPhotography)}
                onProductEdit={this.editProduct}
              />

              <ProductBox
                title="Stailaus"
                products={this.getProductsByType(ProductType.Styling)}
                onProductEdit={this.editProduct}
              />
              <div className="row mb-4">
                <div className="col-12"></div>
              </div>

              <div
                className={`modal ${this.state.openProductEditor && 'open'}`}
                onClick={event => {
                  if (event.target === event.currentTarget) {
                    this.closeProductEditor();
                  }
                }}
              >
                <div className="modal-content">
                  <ProductEditor
                    product={this.state.productEdited}
                    onProductEdit={this.handleProductEditing}
                    onSave={this.saveProduct}
                    onClose={this.closeProductEditor}
                    onDelete={this.deleteProduct}
                  />
                </div>
              </div>
            </>
          )}
          <br />
          <br />

          <div className="text-center mb-5">
            <button className="btn btn-primary" disabled={loading}>
              Tallenna muutokset
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(EditUser);
