import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import LoginView from './LoginView';
import IndexView from './IndexView';
import Navigation from './Navigation';
import AuthStore from '../stores/Auth.store';
import UserStore from '../stores/User.store';
import NewAssignment from './assignment/New';
import ViewAssignment from './assignment/View';
import ListAssignments from './assignment/List';
import ListVirtualStylings from './virtualstyling/List';
import NewVirtualStyling from './virtualstyling/New';
import ListOrganizations from './organization/List';
import ListUsers from './user/List';
import ViewUser from './user/View';
import NewOrganization from './organization/New';
import ListProviders from './providers/List';
import ViewOrganization from './organization/View';
import Alerts from './Alerts';
import PasswordView from './PasswordView';
import ListInvoices from './invoice/List';
import ViewInvoice from './invoice/View';
import ViewPortfolio from './portfolio/View';
import EditPortfolio from './portfolio/Edit';
import EventLogView from './EventLogView';
import Footer from '../Footer';
import ProviderLoginView from './ProviderLoginView';
import AgentRegistration from './AgentRegistration';
import EditOrganization from './organization/Edit';

const Router = BrowserRouter;

@observer
export default class App extends React.Component {
  constructor(props) {
    super(props);
    if (AuthStore.isAuthenticated) {
      AuthStore.afterSuccesfulLogin();
    }
  }

  render() {
    const isLoggedIn = AuthStore.isAuthenticated;

    const AuthorizedRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={renderProps =>
          isLoggedIn ? (
            <Component {...renderProps} />
          ) : (
            <Redirect
              to={{
                pathname: '/kirjaudu'
              }}
            />
          )
        }
      />
    );

    const rootClassNames = classnames('app-container', { unauthorized: !isLoggedIn });

    if (AuthStore.isAuthenticated && UserStore.userDataInProgress) {
      return null;
    }

    const content = (
      <Switch>
        <AuthorizedRoute path="/omat-toimeksiannot" component={ListAssignments} />
        <AuthorizedRoute path="/toimeksianto/uusi" component={NewAssignment} />
        <AuthorizedRoute path="/toimeksianto/:id" component={ViewAssignment} />
        <AuthorizedRoute exact path="/virtuaalistailaus" component={ListVirtualStylings} />
        <AuthorizedRoute path="/virtuaalistailaus/uusi" component={NewVirtualStyling} />
        <AuthorizedRoute path="/selaa-palveluntuottajia" component={ListProviders} />
        <AuthorizedRoute path="/omat-tiedot" component={ViewUser} />
        <AuthorizedRoute exact path="/organisaatiot" component={ListOrganizations} />
        <AuthorizedRoute path="/organisaatiot/uusi" component={NewOrganization} />
        <AuthorizedRoute exact path="/organisaatiot/:id" component={ViewOrganization} />
        <AuthorizedRoute path="/organisaatiot/:id/muokkaa" component={EditOrganization} />
        <AuthorizedRoute exact path="/kayttajat" component={ListUsers} />
        <AuthorizedRoute path="/kayttajat/:id" component={ViewUser} />
        <AuthorizedRoute exact path="/laskut" component={ListInvoices} />
        <AuthorizedRoute path="/laskut/:id" component={ViewInvoice} />
        <AuthorizedRoute exact path="/portfolio" component={ViewPortfolio} />
        <AuthorizedRoute exact path="/portfolio/muokkaa" component={EditPortfolio} />
        <AuthorizedRoute path="/portfolio/:id" component={ViewPortfolio} />
        <AuthorizedRoute path="/tapahtumaloki" component={EventLogView} />

        <Route path="/" exact component={IndexView} />
        <Route path="/rekisteroidy" exact component={AgentRegistration} />
        <Route path="/aseta-salasana/:token" component={PasswordView} />
        <Route path="/kirjaudu" component={LoginView} />
        <Route path="/kirjaudu-palveluntuottaja" component={ProviderLoginView} />
        <Route
          path="/kirjaudu-ulos"
          render={() => {
            AuthStore.logout();
            return null;
          }}
        />
      </Switch>
    );

    return (
      <Router>
        <Navigation />
        <div className={rootClassNames}>
          <Alerts />
          <div id="modal-container" />
          <div className="app">
            <div className="app-content">{content}</div>
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}
