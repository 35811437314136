import React from 'react';

interface Props {
  pictureURL: string;
  isProvider: boolean;
}

const ProfilePic: React.FunctionComponent<Props> = props => {
  const picture = props.isProvider
    ? props.pictureURL
    : `${process.env.REACT_APP_API_URL}/images/${props.pictureURL}`;

  const style = props.pictureURL
    ? { backgroundImage: `url(${picture})` }
    : { backgroundColor: 'grey' };

  return <div className="profile-pic" style={style} />;
};

export default ProfilePic;
