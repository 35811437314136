import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import OrganizationStore from '../../stores/Organization.store';
import { ReactComponent as RightArrowIcon } from '../../assets/icons/RightArrow.svg';

@observer
export default class ListOrganizations extends React.Component<RouteComponentProps> {
  constructor(props) {
    super(props);
    OrganizationStore.getAllOrganizations();
  }

  render() {
    const organizations = OrganizationStore.organizations;

    return (
      <div className="container mt-5">
        <h1 className="mb-3 d-block">Organisaatiot</h1>

        <Link to="/organisaatiot/uusi" className="mb-3 d-block">
          <u>Uusi organisaatio</u>
        </Link>

        <ul className="list-group mb-3">
          {organizations.map(org => (
            <li
              key={org.id}
              className="list-group-item d-flex justify-content-between align-items-center"
              onClick={() => this.props.history.push(`/organisaatiot/${org.id}`)}
            >
              {org.name}
              <div className="flex-center">
                <RightArrowIcon className="icon" width="20" height="20" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
