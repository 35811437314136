import React from 'react';

import { Link, withRouter } from 'react-router-dom';
import { Registration } from '../Api';
import InputField from '../components/InputField';

type State = {
  email: string;
  firstName: string;
  lastName: string;
  businessId: string;
  businessName: string;
  businessAddress: string;
  businessZipcode: string;
  businessCity: string;
  password1: string;
  password2: string;
  error: string;
  step: number;
  registerWithExistingOrganization: boolean;
  loading: boolean;
};

class AgentRegistration extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      businessId: '',
      businessName: '',
      businessAddress: '',
      businessZipcode: '',
      businessCity: '',
      password1: '',
      password2: '',
      step: 0,
      error: '',
      registerWithExistingOrganization: true,
      loading: false
    };
  }

  clearErrors = () => {
    this.setState({
      error: ''
    });
  };

  handleChange = key => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [key]: event.target.value
    } as State);
  };

  handleStep0Submit = async evt => {
    evt.preventDefault();
    const { businessId } = this.state;
    this.setState({
      loading: true
    });

    const organizationExists = await Registration.checkOrg(businessId);

    const response = await fetch(`https://avoindata.prh.fi/tr/v1/${businessId}`);
    const data = await response.json();

    if (data.results.length === 0) {
      this.setState({
        error: `Y-tunnuksella ${businessId} ei löytynyt yritystä. Tarkista, että tunnus on kirjoitettu oikein.`,
        loading: false
      });
      return;
    } else {
      const results = data.results[0];
      const name = results.names[0].name;
      const address = results.addresses[0].street;
      const zipCode = results.addresses[0].postCode;
      const city = results.addresses[0].city;

      this.setState({
        businessName: name || '',
        businessAddress: address || '',
        businessZipcode: zipCode || '',
        businessCity: city || ''
      });
    }

    this.setState({
      step: 1
    });

    if (!organizationExists) {
      this.setState({
        registerWithExistingOrganization: false,
        step: 1,
        error: ''
      });
    } else {
      this.setState({
        step: 2,
        error: ''
      });
    }

    this.setState({
      loading: false
    });

    try {
    } catch (e) {}
  };

  handleStep1Submit = async evt => {
    evt.preventDefault();

    this.setState({
      step: 2
    });
  };

  handleStep2Submit = async evt => {
    evt.preventDefault();

    const {
      registerWithExistingOrganization,
      email,
      firstName,
      lastName,
      businessId,
      businessName,
      businessAddress,
      businessCity,
      businessZipcode,
      password1
    } = this.state;

    this.setState({
      loading: true
    });

    try {
      if (registerWithExistingOrganization) {
        await Registration.register(email, firstName, lastName, businessId, password1);
      } else {
        await Registration.registerWithCompany(
          email,
          firstName,
          lastName,
          businessId,
          businessName,
          businessAddress,
          businessCity,
          businessZipcode,
          password1
        );
      }

      this.setState({
        step: 3
      });
    } catch (e) {
      const msg = e instanceof Error ? e.message : 'Virhe';

      this.setState({
        error: msg,
        loading: false
      });
    }
  };

  isValidStep = (step: number) => {
    const {
      email,
      firstName,
      lastName,
      businessId,
      businessName,
      businessAddress,
      businessCity,
      businessZipcode,
      password1,
      password2
    } = this.state;

    switch (step) {
      case 0:
        return email && firstName && lastName && businessId;

      case 1:
        return businessName && businessAddress && businessCity && businessZipcode;

      case 2:
        return password1 && password2 && password1 === password2;
    }
  };

  render() {
    const {
      email,
      firstName,
      lastName,
      businessId,
      businessName,
      businessAddress,
      businessCity,
      businessZipcode,
      password1,
      password2,
      error,
      step,
      registerWithExistingOrganization,
      loading
    } = this.state;

    const isValid = this.isValidStep(step);

    const step0 = (
      <form className="full-width text-center" onSubmit={this.handleStep0Submit}>
        <InputField
          id="email"
          label="Sähköposti"
          type="email"
          onChange={this.handleChange('email')}
          value={email}
        />
        <InputField
          id="email"
          label="Etunimi"
          type="text"
          onChange={this.handleChange('firstName')}
          value={firstName}
        />
        <InputField
          id="email"
          label="Sukunimi"
          type="text"
          onChange={this.handleChange('lastName')}
          value={lastName}
        />
        <InputField
          id="email"
          label="Y-tunnus"
          type="text"
          onChange={this.handleChange('businessId')}
          value={businessId}
        />

        <button className="btn btn-primary" disabled={!isValid || loading}>
          Jatka
        </button>
      </form>
    );

    const step1 = (
      <form className="full-width text-center" onSubmit={this.handleStep1Submit}>
        <p className="mb-3">
          Tarkista vielä, että y-tunnuksella <b>{businessId}</b> löytyneet tiedot ovat oikein.
        </p>

        <InputField
          id="business-name"
          label="Yrityksen nimi"
          type="text"
          onChange={this.handleChange('businessName')}
          value={businessName}
        />
        <InputField
          id="business-address"
          label="Yrityksen osoite"
          type="text"
          onChange={this.handleChange('businessAddress')}
          value={businessAddress}
        />
        <InputField
          id="business-address"
          label="Yrityksen postinumero"
          type="text"
          onChange={this.handleChange('businessZipcode')}
          value={businessZipcode}
        />
        <InputField
          id="business-address"
          label="Yrityksen postitoimipaikka"
          type="text"
          onChange={this.handleChange('businessCity')}
          value={businessCity}
        />

        <button className="btn btn-primary" disabled={!isValid || loading}>
          Jatka
        </button>
      </form>
    );

    const step2 = (
      <form className="full-width text-center" onSubmit={this.handleStep2Submit}>
        <p className="mb-3">Valitse vielä salasana, millä kirjaudut palveluun</p>

        <InputField
          id="pw1"
          label="Salasana"
          type="password"
          onChange={this.handleChange('password1')}
          value={password1}
        />
        <InputField
          id="pw2"
          label="Salasana uudestaan"
          type="password"
          onChange={this.handleChange('password2')}
          value={password2}
        />

        <button className="btn btn-primary" disabled={!isValid || loading}>
          Rekisteröidy
        </button>
      </form>
    );

    const step3 = (
      <div className="text-center">
        <p className="mb-3">
          Rekisteröityminen onnistui! Voit nyt kirjautua sisään sähköpostilla ja valitsemallasi
          salasanalla
        </p>
        <Link to={`/kirjaudu`}>
          <button className="btn btn-primary mb-3">Kirjaudu sisään</button>
        </Link>
      </div>
    );

    const steps = [step0, step1, step2, step3];

    return (
      <div className="registration">
        <h1 className="plain text-center">Kiinteistövälittäjän rekisteröityminen</h1>
        {businessName && (
          <p className="text-center mb-2">
            Olet
            {registerWithExistingOrganization
              ? ' rekisteröitymässä yritykseen'
              : ' rekisteröimässä yrityksen'}
            <br /> <b>{businessName}</b>
          </p>
        )}
        {steps[step]}
        {error && <div className="font-weight-bold text-center text-danger">{error}</div>}
      </div>
    );
  }
}

export default withRouter(AgentRegistration);
