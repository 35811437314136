import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { Portfolio, User } from '../../Api';
import UserStore from '../../stores/User.store';
import TextArea from '../../components/TextArea';
import StatusBar from '../../components/StatusBar';
import AlertStore from '../../stores/Alert.store';
import InputField from '../../components/InputField';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';

interface RouteParams {
  id: string;
}

class EditPortfolio extends React.Component<RouteComponentProps<RouteParams>, any> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showDropZone: false,
      uploading: false,
      selectedItems: []
    };
  }

  componentDidMount = async () => {
    await this.refresh();
  };

  refresh = async () => {
    try {
      this.setState({
        loading: true
      });

      const user = await User.getUserWithPortfolio(UserStore.userData.id);
      this.setState({
        portfolio: user.portfolio,
        loading: false
      });
    } catch (e) {}
  };

  handleDescriptionChange = evt => {
    const value = evt.target.value;

    this.setState(prevState => ({
      portfolio: {
        ...prevState.portfolio,
        description: value
      }
    }));
  };

  handleImageUpload = async (acceptedFiles, rejectedFiles, evt) => {
    try {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.addEventListener('loadend', async () => {
        await Portfolio.upload(this.state.portfolio.id, file.name, reader.result as ArrayBuffer);

        AlertStore.addAlert({
          type: 'success',
          message: `${file.name} lisätty`
        });

        this.refresh();
      });
    } catch (e) {
      AlertStore.addAlert({
        type: 'danger',
        message: `Virhe kuvan lisäämisessä: ${e}`
      });
    }
  };

  handleImageDelete = async () => {
    const itemsToDelete = this.state.selectedItems;

    try {
      await Portfolio.delete(itemsToDelete);

      AlertStore.addAlert({
        type: 'success',
        message:
          itemsToDelete.length > 1 ? `${itemsToDelete.length} kuvaa poistettu` : '1 kuva poistettu'
      });

      this.setState({
        selectedItems: []
      });

      this.refresh();
    } catch (e) {}
  };

  handleItemSelect = id => evt => {
    if (this.state.selectedItems.some(i => i === id)) {
      this.setState(prevState => ({
        selectedItems: prevState.selectedItems.filter(i => i !== id)
      }));
    } else {
      this.setState(prevState => ({
        selectedItems: [...prevState.selectedItems, id]
      }));
    }
  };

  handleDescriptionSave = async () => {
    try {
      const description = this.state.portfolio.description;

      await Portfolio.save(description);

      AlertStore.addAlert({
        type: 'success',
        message: 'Portfolion kuvaus tallennettu'
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  handleVideoChange = (rowId, property) => async evt => {
    evt.preventDefault();

    let rows = this.state.portfolio.items;
    const index = rows.findIndex(r => r.id === rowId || r.temporaryId === rowId);

    if (index > -1) {
      rows[index][property] = evt.target.value;

      this.setState(prevState => ({
        portfolio: {
          ...prevState.portfolio,
          rows
        }
      }));
    }
  };

  toggleDropZone = () => {
    this.setState(prevState => ({
      showDropZone: !prevState.showDropZone
    }));
  };

  addVideo = () => {
    // Temporary ID which is used for non-saved rows. Rows are given real ID upon saving to database
    const id = Date.now();

    this.setState(prevState => ({
      portfolio: {
        ...prevState.portfolio,
        items: [
          ...prevState.portfolio.items,
          {
            temporaryId: id,
            type: 'Video',
            path: ''
          }
        ]
      }
    }));
  };

  deleteVideo = (rowId: number) => evt => {
    evt.preventDefault();

    let rows = this.state.portfolio.items;
    const index = rows.findIndex(r => r.id === rowId || r.temporaryId === rowId);

    if (index > -1) {
      rows.splice(index, 1);

      this.setState(prevState => ({
        portfolio: {
          ...prevState.portfolio,
          rows
        }
      }));
    }
  };

  saveVideos = async () => {
    try {
      const videos = this.state.portfolio.items.filter(item => item.type === 'Video');
      await Portfolio.saveVideos(this.state.portfolio.id, videos);

      AlertStore.addAlert({
        type: 'success',
        message: 'Videot päivitetty'
      });
    } catch (e) {
      AlertStore.addAlert({
        type: 'danger',
        message: 'Virhe videoiden päivittämisessä'
      });
    }
  };

  render() {
    const { portfolio, showDropZone, loading, selectedItems } = this.state;
    const maxSize = 5242880;

    if (loading) {
      return null;
    }

    const photos = portfolio.items.filter(item => item.type === 'Photo');
    const videos = portfolio.items.filter(item => item.type === 'Video');

    return (
      <div className="portfolio edit-view">
        <StatusBar link="/portfolio" />
        <div className="container mt-5">
          <h1 className="mb-5">Portfolion muokkaus</h1>

          <h2>Oma kuvaus</h2>
          <TextArea
            id="description"
            onChange={this.handleDescriptionChange}
            value={portfolio.description || ''}
          />

          <button className="btn btn-primary small mb-3" onClick={this.handleDescriptionSave}>
            Tallenna kuvaus
          </button>

          <div className="mb-3">
            <div className="row">
              <div className="col-6">
                <h2>Kuvat</h2>

                <span className="link d-block mb-4" onClick={this.toggleDropZone}>
                  Lisää uusi kuva
                </span>
              </div>

              <div className="col-6">
                {selectedItems.length > 0 && (
                  <div className="text-center">
                    <button className="btn btn-danger small mb-3" onClick={this.handleImageDelete}>
                      Poista {selectedItems.length > 1 ? `${selectedItems.length} kuvaa` : '1 kuva'}
                    </button>
                  </div>
                )}
              </div>
            </div>

            {showDropZone && (
              <Dropzone
                accept="image/jpg, image/jpeg, image/png"
                minSize={0}
                maxSize={maxSize}
                onDrop={this.handleImageUpload}
              >
                {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => {
                  return (
                    <section className={classNames('dropzone mt-3 mb-3', { active: isDragActive })}>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <span>Klikkaa tai pudota tiedosto tähän</span>
                        {isDragReject && (
                          <span className="d-block error">
                            Virhe: Kuvan täytyy olla jpg- tai png-muodossa
                          </span>
                        )}
                      </div>
                    </section>
                  );
                }}
              </Dropzone>
            )}

            <div className="mb-5">
              <div className="gallery row pt-3">
                {photos.length === 0 && (
                  <div className="col-12">
                    <small className="text-muted">Ei kuvia</small>
                  </div>
                )}
                {photos.map(photo => (
                  <div key={photo.id} className="col-3 mb-3">
                    <img
                      alt=""
                      className={selectedItems.some(i => i === photo.id) ? 'active' : ''}
                      src={`${process.env.REACT_APP_API_URL}/images/${photo.thumbnailPath}`}
                      onClick={this.handleItemSelect(photo.id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <h2>Videot</h2>
          <div>
            <span className="link d-block mb-4" onClick={this.addVideo}>
              Lisää uusi video
            </span>

            {videos.map(video => (
              <>
                <div key={video.id} className="" style={{ position: 'relative', paddingRight: 55 }}>
                  <InputField
                    id={`vidoe-url-${video.id || video.temporaryId}`}
                    label="Videon URL"
                    type="text"
                    onChange={this.handleVideoChange(video.id || video.temporaryId, 'path')}
                    value={video.path}
                  />
                  <CloseIcon
                    width="20"
                    height="20"
                    style={{ position: 'absolute', top: 36, right: 15 }}
                    onClick={this.deleteVideo(video.id || video.temporaryId)}
                  />
                </div>
                <hr />
              </>
            ))}

            <button className="btn btn-primary small mb-3" onClick={this.saveVideos}>
              Tallenna videot
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditPortfolio);
