import React from 'react';
import { observer } from 'mobx-react';
import AuthStore from '../stores/Auth.store';
import { Auth } from '../Api';
import { getParameterByName } from '../util';
import LoadingSpinner from '../components/LoadingSpinner';

type State = {
  email: string;
  password: string;
  googleLoginURL: string;
  isProcessingGoogleLogin: boolean;
};

@observer
class ProviderLoginView extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      googleLoginURL: '',
      isProcessingGoogleLogin: false
    };

    if (AuthStore.isAuthenticated) {
      this.props.history.push(`/`);
    }
  }

  componentDidMount = async () => {
    const hash = window.location.hash;
    const code = getParameterByName('code', hash);

    if (code) {
      this.setState({
        isProcessingGoogleLogin: true
      });
      AuthStore.googleLogin(code);
    } else {
      this.setState({
        googleLoginURL: await Auth.getGoogleLoginURL()
      });
    }
  };

  handleChange = key => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [key]: event.target.value
    } as State);
  };

  handleSubmit = async evt => {
    evt.preventDefault();

    AuthStore.login(this.state.email, this.state.password);
  };

  render() {
    const { googleLoginURL, isProcessingGoogleLogin } = this.state;

    if (isProcessingGoogleLogin) {
      return <LoadingSpinner />;
    }

    return (
      <div className="login">
        <h1 className="plain text-center">Palveluntuottajien kirjautuminen</h1>
        <p className="text-center">
          Palveluntuottajana kirjaudut sisään Bondriin omilla Google-tunnuksillasi.
        </p>
        <div className="mt-5">
          <a href={googleLoginURL} className="glogin">
            Palveluntuottajien kirjautuminen
          </a>
        </div>
      </div>
    );
  }
}

export default ProviderLoginView;
