import React from 'react';
import hero from '../assets/img/hero.jpg';

export default class FrontpageBanner extends React.Component {
  render() {
    return (
      <div className="banner" style={{ backgroundImage: `url(${hero})`, backgroundSize: 'cover' }}>
        <div className="container banner-text">
          Kiinteistömarkkinoinnin kohtauspaikka
          <br />
          Bondr.
        </div>
      </div>
    );
  }
}
