import React from 'react';
import classNames from 'classnames';

type Props = {
  id: string;
  label: string;
  type: 'text' | 'password' | 'number' | 'tel' | 'email';
  value?: string | number;
  classes?: string;
  onChange: any;
  disabled?: boolean;
};

type State = {
  focus: boolean;
};

export default class InputField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      focus: false
    };
  }

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  render() {
    const { id, label, type, value, disabled, classes } = this.props;
    const rootClasses = classNames(`form-group ${classes ? classes : ''}`, {
      shrink: this.state.focus || !!this.props.value
    });

    return (
      <div className={rootClasses}>
        <label htmlFor={id}>{label}</label>
        <div className="input-root">
          <input
            type={type}
            className="form-control"
            id={id}
            value={value}
            disabled={disabled}
            onChange={this.props.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
          />
        </div>
      </div>
    );
  }
}
