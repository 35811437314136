import React from 'react';
import { withRouter } from 'react-router-dom';

class ListVirtualStylings extends React.Component<any, any> {
  render() {
    return (
      <div className="container pt-5">
        <h1 className="mb-5">Omat virtuaalistailaukset</h1>

        <p>Tulossa pian</p>
      </div>
    );
  }
}

export default withRouter(ListVirtualStylings);
