import React from 'react';
import Modal from 'react-modal';
import { ReactComponent as CloseIcon } from '../assets/icons/Close.svg';

Modal.setAppElement('#root');

const ModalContainer = props => {
  return (
    <Modal
      isOpen={props.isOpen}
      className="react-modal-container"
      overlayClassName="react-modal-overlay"
    >
      <div className="react-modal-content">
        <div className="react-modal-header">
          <h2 className="mb-0">{props.title}</h2>

          <CloseIcon width="20" height="20" onClick={props.handleClose} className="close-button" />
        </div>

        <div className="modal-body">{props.children}</div>
      </div>
    </Modal>
  );
};

export default ModalContainer;
