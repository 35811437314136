import React from 'react';
import { User } from '../../Api';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import UserStore from '../../stores/User.store';
import classNames from 'classnames';

interface RouteParams {
  id: string;
}

class ViewPortfolio extends React.Component<RouteComponentProps<RouteParams>, any> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeTab: 0
    };
  }

  componentDidMount = async () => {
    try {
      const user = await User.getUserWithPortfolio(
        this.props.match.params.id || UserStore.userData.id
      );

      this.setState({
        user,
        loading: false
      });
    } catch (e) {}
  };

  changeActiveTab = tab => evt => {
    this.setState({
      activeTab: tab
    });
  };

  renderPortfolioContent = () => {
    const { activeTab, user } = this.state;

    const items = user.portfolio.items ? user.portfolio.items : [];

    if (activeTab === 0) {
      const photos = items.filter(item => item.type === 'Photo');
      return (
        <div className="row pt-3 align-items-center">
          {items &&
            photos.map(photo => (
              <div key={photo.id} className="col-6 col-md-2 mb-3 text-center">
                <a
                  href={`${process.env.REACT_APP_API_URL}/images/${photo.path}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt=""
                    src={`${process.env.REACT_APP_API_URL}/images/${photo.thumbnailPath}`}
                  />
                </a>
              </div>
            ))}
          {photos.length === 0 && (
            <div className="col-12">
              <div className="text-center mb-5">
                <small className="text-muted">Ei kuvia</small>
              </div>
            </div>
          )}
        </div>
      );
    }

    const videos = items.filter(item => item.type === 'Video');

    return (
      <div>
        {videos.map(video => (
          <div key={video.id} className="col-12 col-md-4 mb-3 text-center">
            <a href={video.path} target="_blank" rel="noopener noreferrer">
              <img alt="" src={video.thumbnailPath} style={{ maxWidth: '100%' }} />
            </a>
          </div>
        ))}
        {videos.length === 0 && (
          <div className="col-12">
            <div className="text-center mb-5">
              <small className="text-muted">Ei videoita</small>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { user, activeTab, loading } = this.state;

    if (loading) {
      return null;
    }

    return (
      <div className="portfolio">
        <div className="container mt-5">
          <div className="portfolio-title">
            <h1 className="mb-5">Portfolio</h1>

            {user.id === UserStore.userData.id && (
              <Link className="link" to={`/portfolio/muokkaa`}>
                Muokkaa portfoliota
              </Link>
            )}
          </div>

          <div className="portfolio-header">
            <div className="picture">{user.picture && <img src={user.picture} alt="" />}</div>
            <div className="name">
              <span className="text-large">{user.firstName}</span>
              <br />
              <span className="text-large">{user.lastName}</span>
            </div>
          </div>

          <div className="portfolio-description mb-5">
            <span className="title">Oma kuvaus: </span>
            <div className="description">{user.portfolio.description}</div>
          </div>

          <div className="portfolio-controls">
            <div
              className={classNames('control', {
                active: activeTab === 0
              })}
              onClick={this.changeActiveTab(0)}
            >
              Valokuvat
            </div>

            <div
              className={classNames('control', {
                active: activeTab === 1
              })}
              onClick={this.changeActiveTab(1)}
            >
              Videot
            </div>
          </div>
        </div>

        <div className="container gallery mt-5">{this.renderPortfolioContent()}</div>
      </div>
    );
  }
}

export default withRouter(ViewPortfolio);
