import React from 'react';
import { observer } from 'mobx-react';
import InputField from '../components/InputField';
import AuthStore from '../stores/Auth.store';
import { Auth } from '../Api';
import { getParameterByName } from '../util';
import LoadingSpinner from '../components/LoadingSpinner';
import { Link, withRouter } from 'react-router-dom';
import AlertStore from '../stores/Alert.store';

type State = {
  email: string;
  password: string;
  googleLoginURL: string;
  isProcessingGoogleLogin: boolean;
  resettingPassword: boolean;
};

@observer
class LoginView extends React.Component<any, State> {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      googleLoginURL: '',
      isProcessingGoogleLogin: false,
      resettingPassword: false
    };

    if (AuthStore.isAuthenticated) {
      this.props.history.push(`/`);
    }
  }

  componentDidMount = async () => {
    const hash = window.location.hash;
    const code = getParameterByName('code', hash);

    if (code) {
      this.setState({
        isProcessingGoogleLogin: true
      });
      AuthStore.googleLogin(code);
    } else {
      this.setState({
        googleLoginURL: await Auth.getGoogleLoginURL()
      });
    }
  };

  handleChange = key => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [key]: event.target.value
    } as State);
  };

  handleSubmit = async evt => {
    evt.preventDefault();

    AuthStore.login(this.state.email, this.state.password);
  };

  handlePasswordReset = async evt => {
    evt.preventDefault();

    try {
      await Auth.resetPassword(this.state.email);

      AlertStore.addAlert({
        type: 'success',
        message: `Salasanan palautus onnistui. Saat pian sähköpostiisi linkin, mistä pääset asettamaan uuden salasanan.`,
        timeout: 10000
      });
    } catch (e) {
      console.log(e);
    }

    this.setState({
      resettingPassword: false
    });
  };

  render() {
    const { inProgress, errors } = AuthStore;
    const {
      email,
      password,
      googleLoginURL,
      isProcessingGoogleLogin,
      resettingPassword
    } = this.state;

    if (isProcessingGoogleLogin) {
      return <LoadingSpinner />;
    }

    if (resettingPassword) {
      return (
        <div className="login">
          <h1 className="plain text-center">Salasanan palautus </h1>
          <form className="full-width text-center" onSubmit={this.handlePasswordReset}>
            <InputField
              id="email"
              label="Sähköposti"
              type="text"
              onChange={this.handleChange('email')}
              value={email}
              disabled={inProgress}
            />

            {!!errors && <div className="error mb-2">Kirjautuminen epäonnistui.</div>}

            <button className="btn btn-primary" disabled={inProgress}>
              Palauta salasana
            </button>
          </form>
        </div>
      );
    }

    return (
      <div className="login">
        <h1 className="plain text-center">Kiinteistövälittäjien kirjautuminen</h1>
        <form className="full-width text-center" onSubmit={this.handleSubmit}>
          <InputField
            id="email"
            label="Sähköposti"
            type="text"
            onChange={this.handleChange('email')}
            value={email}
            disabled={inProgress}
          />
          <InputField
            id="password"
            label="Salasana"
            type="password"
            onChange={this.handleChange('password')}
            value={password}
            disabled={inProgress}
          />

          {!!errors && <div className="error mb-2">Kirjautuminen epäonnistui.</div>}

          <button className="btn btn-primary" disabled={inProgress}>
            Kirjaudu sisään välittäjänä
          </button>
        </form>
        <div className="mt-3 text-center">
          <div className="mt-2">
            <Link to="/rekisteroidy">
              <strong>Uusi kiinteistövälittäjä? Rekisteröidy tästä</strong>
            </Link>
          </div>
          <br />
          <span
            onClick={() => {
              this.setState({ resettingPassword: true });
            }}
            style={{ cursor: 'pointer' }}
          >
            Unohditko salasanasi?
          </span>
        </div>
        <div className="mt-3">
          <a href={googleLoginURL} className="glogin">
            Palveluntuottajien kirjautuminen
          </a>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginView);
