import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Organization } from '../../Api';
import UserStore from '../../stores/User.store';
import { ReactComponent as EmptyStarIcon } from '../../assets/icons/Empty-Star.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/Filter.svg';
import AlertStore from '../../stores/Alert.store';

@observer
export default class ListProviders extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      favourites: [],
      showFilters: false,
      cityFilter: ''
    };

    UserStore.getProviders();
  }

  componentDidMount = async () => {
    const favourites = await Organization.getFavouritesIds(UserStore.organizationId);

    this.setState({
      favourites
    });
  };

  isFavourite = providerId => {
    return this.state.favourites && this.state.favourites.includes(providerId);
  };

  toggleFavourite = provider => async (event: React.MouseEvent<HTMLElement>) => {
    const providerId = provider.id;
    const newFavourites = await Organization.setFavourite(providerId, UserStore.organizationId);

    this.setState({
      favourites: newFavourites
    });

    if (this.isFavourite(providerId)) {
      AlertStore.addAlert({
        type: 'success',
        message: `${provider.firstName} ${provider.lastName} lisätty suosikkeihin`
      });
    } else {
      AlertStore.addAlert({
        type: 'success',
        message: `${provider.firstName} ${provider.lastName} poistettu suosikeista`
      });
    }
  };

  toggleShowingFilters = () => {
    this.setState(prevState => ({
      showFilters: !prevState.showFilters
    }));
  };

  setFilterCity = evt => {
    const city = evt.target.value;
    this.setState({
      cityFilter: city
    });
  };

  getProvidersCities = () => {
    const providers = UserStore.providers;
    const cities = Array.from(new Set(providers.map(provider => provider.city)));
    return cities;
  };

  render() {
    const { showFilters, cityFilter } = this.state;
    const providers = UserStore.providers;
    const isAdmin = UserStore.isAdmin;

    const isLoading = UserStore.inProgress || !providers;

    return (
      <div className="container mt-5">
        <div className="mb-3">
          <div className="row">
            <div className="col-10">
              <h1 className="mb-3">Selaa palveluntuottajia</h1>
            </div>
            <div className="col-2">
              <FilterIcon
                width="22"
                height="19"
                style={{ float: 'right', marginTop: 2 }}
                onClick={this.toggleShowingFilters}
              />
            </div>
          </div>
        </div>

        {showFilters && (
          <div className="filters">
            <h2>Näytä palveluntuottajat kaupungista</h2>
            <div className="form-group">
              <select className="form-control" onChange={this.setFilterCity}>
                <option />
                {this.getProvidersCities().map((city: any) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
                ))
              </select>
            </div>
          </div>
        )}

        {isAdmin && (
          <div className="alert alert-danger">
            Huom! Selaat palveluntuottajia sovelluksen pääkäyttäjänä. Pääkäyttäjänä et kuulu
            mihinkään organisaation ja et näin ollen voi asettaa suosikkeja.
          </div>
        )}

        {!isLoading &&
          providers
            .filter(provider => cityFilter === '' || cityFilter === provider.city)
            .map(provider => {
              return (
                <div key={provider.id} className="bondr-card row no-gutters p-3 mb-3">
                  <div className="col-4 col-md-1">
                    <div
                      className="profile-pic"
                      style={{ backgroundImage: `url(${provider.picture})` }}
                    ></div>
                  </div>
                  <div className="col-8 col-md-11 content p-2 pl-3">
                    <h3 className="title mb-1">
                      {provider.firstName} {provider.lastName}
                    </h3>
                    <div>
                      <small className="text-muted">{provider.city}</small>
                    </div>
                    <div>
                      <Link to={`/portfolio/${provider.id}`}>
                        <button className="btn btn-primary extra-small mt-3">Referenssit</button>
                      </Link>
                    </div>
                    {!isAdmin && (
                      <button
                        onClick={this.toggleFavourite(provider)}
                        className="btn btn-primary extra-small mt-3"
                      >
                        {this.isFavourite(provider.id) ? 'Poista suosikeista' : 'Lisää suosikiksi'}
                      </button>
                    )}

                    {!isAdmin && (
                      <div onClick={this.toggleFavourite(provider)} className="favourites-star">
                        <EmptyStarIcon
                          width="15"
                          height="15"
                          fill={this.isFavourite(provider.id) ? '#4858E9' : '#666'}
                          style={{ float: 'right', marginTop: 2 }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
      </div>
    );
  }
}
