// Set API endpoints in .env.local file
import { ErrorObject } from 'ajv';
import AuthStore from './stores/Auth.store';
import { Product } from './typings/Product';
import { Organization as TOrganization, OrganizationAdmin } from './typings/Organization';

const defaultHeaders = {
  'Content-Type': 'application/json'
};

const get = <T>(url: string): Promise<T> => makeRequest<T>('GET', url);
const post = <T>(url: string, data: object): Promise<T> => makeRequest<T>('POST', url, data);
/* const put = <T>(url: string, data: object): Promise<T> => makeRequest<T>('PUT', url, data);
const patch = <T>(url: string, data: object): Promise<T> => makeRequest<T>('PATCH', url, data);
const _delete = <T>(url: string, data: object): Promise<T> => makeRequest<T>('DELETE', url, data); */

function makeRequest<T>(method: string, url: string, data?: object): Promise<T> {
  let init: RequestInit = {
    method,
    headers: getHeaders(),
    credentials: 'same-origin',
    body: data ? JSON.stringify(data) : undefined
  };

  if (data && data instanceof FormData) {
    init = { method, body: data };
  }

  if (data && data instanceof Map) {
    let obj: any = {};
    data.forEach((v, k) => {
      obj[k] = v;
    });
    init.body = JSON.stringify(obj);
  }

  return fetch(process.env.REACT_APP_API_URL + url, init)
    .then(handleErrors())
    .then(refreshToken)
    .then(response => {
      if (response.status === 204) {
        return null as any;
      }

      return response.json() as Promise<T>;
    })
    .catch(err => {
      console.error(err);
      throw err;
    });

  function handleErrors(isRetry = false) {
    return async (response: Response): Promise<Response> => {
      if (!response.ok) {
        if (response.status === 401 && AuthStore.token && !isRetry) {
          //await AuthStore.refreshToken(AuthStore.token);

          // retry original request with updated token
          init.headers = getHeaders();
          return fetch(process.env.REACT_APP_API_URL + url, init).then(handleErrors(true));
        }

        if (response.status === 401 && AuthStore.token) {
          // clear invalid token
          AuthStore.logout();
        }

        let content;
        try {
          content = await response.json();
        } catch (e) {}

        throw new ApiError(response, content);
      }

      return response;
    };
  }
}

function getHeaders() {
  if (AuthStore.token) {
    return {
      ...defaultHeaders,
      Authorization: `Bearer ${AuthStore.token}`
    };
  }

  return defaultHeaders;
}

// Errors from backend come in this format
type ErrorContent = {
  status: number;
  name: string;
  message: string;
  type?: Array<ErrorObject>;
};

export class ApiError extends Error {
  constructor(public response: Response, public content?: ErrorContent) {
    super(content ? content.message : response.statusText);
  }
}

function refreshToken(response: Response): Response {
  const newToken = response.headers.get('X-Token-Refresh');
  if (newToken) {
    AuthStore.setToken(newToken);
  }
  return response;
}

const Auth = {
  login: (email: string, password: string) =>
    post<{ token: string }>('/auth/login', { email, password }),
  glogin: (code: string) => post<{ token: string }>('/auth/glogin', { code }),
  findByPasswordToken: (token): Promise<any> => get(`/auth/password/${token}`),
  setPasswordWithToken: (token, password, password2) =>
    post('/auth/password', { token, password, password2 }),
  refreshToken: (token: string) => post<{ token: string }>('/auth/token', { token }),
  getGoogleLoginURL: (): Promise<any> => get('/auth/glogin'),
  resetPassword: (email: string) => post<{ email: string }>('/auth/reset-password', { email })
};

const User = {
  deleteProduct: id => post('/user/product/delete', { id }),
  findById: (id): Promise<any> => get(`/user/${id}`),
  getUserdata: (): Promise<any> => get('/user'),
  getProviders: (): Promise<any> => get('/user/providers'),
  getAvailableProviders: (services: any, dateTime: any, address: string, duration: number) =>
    post<{ token: string }>('/user/available-providers', { services, dateTime, address, duration }),
  getAll: (): Promise<any> => get('/user/all'),
  getCalendars: (id): Promise<any> => get(`/user/calendars/${id}`),
  getFreeTimes: (id): Promise<any> => get(`/user/freetimes/${id}`),
  getProducts: (id): Promise<Product[]> => get(`/user/${id}/products`),
  getUserWithPortfolio: (id): Promise<any> => get(`/user/${id}/portfolio`),
  setPasswordWithoutToken: (oldPassword: string, newPassword: string, newPasswordRepeat: string) =>
    post('/user/set-password', { oldPassword, newPassword, newPasswordRepeat }),
  setAvatar: (filename: string, file: ArrayBuffer): Promise<any> =>
    post('/user/set-avatar', { filename, file }),
  update: (userId, userData) => post('/user', { userId, userData }),
  updateProduct: product => post('/user/product', { product }),
  toggleActive: (userId: number, activityStatus: boolean) =>
    post('/user/toggle-active', { userId, activityStatus })
};

const Organization = {
  create: (organization: TOrganization, admin: OrganizationAdmin) =>
    post('/organization/create', { organization, admin }),
  getAll: (): Promise<TOrganization[]> => get('/organization'),
  getFavourites: (organizationId: number): Promise<any> =>
    get(`/organization/${organizationId}/favourites`),
  getFavouritesIds: (organizationId: number): Promise<number[]> =>
    get(`/organization/${organizationId}/favourite-ids`),
  setFavourite: (providerId, organizationId) =>
    post(`/organization/${organizationId}/favourite`, { providerId }),
  findById: (id: number): Promise<TOrganization> => get(`/organization/${id}`),
  addMember: (organizationId: number, user) =>
    post('/organization/addmember', { organizationId, user }),
  update: organization => post(`/organization/${organization.id}/update`, { organization })
};

const Assignment = {
  get: (id): Promise<any> => get(`/assignment/${id}`),
  getAll: (): Promise<any> => get(`/assignment/`),
  create: assignment => post('/assignment', { assignment }),
  sendMessage: (assignmentId, msg) => post('/assignment/send-message', { assignmentId, msg }),
  setCompleted: (assignmentId, sharingLink) =>
    post('/assignment/set-completed', { assignmentId, sharingLink }),
  setAccepted: assignmentId => post('/assignment/set-accepted', { assignmentId })
};

const Invoice = {
  get: (id): Promise<any> => get(`/invoice/${id}`),
  getAll: (): Promise<any> => get(`/invoice/`),
  update: invoice => post('/invoice/update', { invoice })
};

const Portfolio = {
  upload: (id: number, filename: string, file: ArrayBuffer): Promise<any> =>
    post('/portfolio/upload', { id, filename, file }),
  delete: (itemsToDelete: number): Promise<any> => post('/portfolio/delete', { itemsToDelete }),
  save: (description: string): Promise<any> => post('/portfolio/save', { description }),
  saveVideos: (id: number, videos: any[]): Promise<any> => post('/portfolio/video', { id, videos })
};

const UserEvent = {
  get: (): Promise<any> => get(`/userevents/`)
};

const Registration = {
  checkOrg: (businessId: string): Promise<boolean> => get(`/registration/prefetch/${businessId}`),
  register: (
    email: string,
    firstName: string,
    lastName: string,
    businessId: string,
    password: string
  ): Promise<any> =>
    post('/registration/register', { email, firstName, lastName, businessId, password }),
  registerWithCompany: (
    email: string,
    firstName: string,
    lastName: string,
    businessId: string,
    businessName: string,
    businessAddress: string,
    businessCity: string,
    businessZipcode: string,
    password: string
  ): Promise<any> =>
    post('/registration/register-with-company', {
      email,
      firstName,
      lastName,
      businessId,
      businessName,
      businessAddress,
      businessCity,
      businessZipcode,
      password
    })
};

export { Auth, User, Organization, Assignment, Invoice, Portfolio, UserEvent, Registration };
