import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import UserStore from '../../stores/User.store';

enum Roles {
  admin = 'Admin',
  provider = 'Palveluntuottaja',
  agent = 'Kiinteistövälittäjä'
}

@observer
export default class ListUsers extends React.Component {
  constructor(props) {
    super(props);

    UserStore.getAllUsers();
  }

  render() {
    const users = UserStore.users;

    const activatedUsers = users.filter(u => u.active);
    const nonActivatedUsers = users.filter(u => !u.active);

    return (
      <div className="container mt-5">
        <h1 className="mb-3 d-block">Käyttäjät</h1>

        <div className="tagline mb-3">Yhteensä {users.length} käyttäjää</div>

        <h2>Aktivoimattomat käyttäjät</h2>
        <ul className="user-list mb-3">
          {nonActivatedUsers.map(u => (
            <Link key={u.id} to={`/kayttajat/${u.id}`}>
              <li className="user-list-item">
                <div>
                  <strong>{`${u.firstName} ${u.lastName}`}</strong>
                </div>
                <div>
                  <div className="d-block d-sm-inline mr-2">
                    <span className="user-list-item-label">Email: </span>
                    {u.email}
                  </div>
                  <div className="d-inline mr-2">
                    <span className="user-list-item-label">Rooli: </span>
                    {Roles[u.role]}
                  </div>
                  <div className="d-block d-sm-inline mr-2">
                    <span className="user-list-item-label">Status: </span>
                    {u.active ? 'Aktivoitu' : 'Ei aktivoitu'}
                  </div>
                </div>
              </li>
            </Link>
          ))}
        </ul>

        <h2>Aktivoidut käyttäjät</h2>
        <ul className="user-list">
          {activatedUsers.map(u => (
            <Link key={u.id} to={`/kayttajat/${u.id}`}>
              <li className="user-list-item">
                <div>
                  <strong>{`${u.firstName} ${u.lastName}`}</strong>
                </div>
                <div>
                  <div className="d-block d-sm-inline mr-2">
                    <span className="user-list-item-label">Email: </span>
                    {u.email}
                  </div>
                  <div className="d-inline mr-2">
                    <span className="user-list-item-label">Rooli: </span>
                    {Roles[u.role]}
                  </div>
                  <div className="d-block d-sm-inline mr-2">
                    <span className="user-list-item-label">Status: </span>
                    {u.active ? 'Aktivoitu' : 'Ei aktivoitu'}
                  </div>
                </div>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    );
  }
}
