import React from 'react';
import InputField from '../../components/InputField';
import AlertStore from '../../stores/Alert.store';
import { Organization } from '../../Api';
import { Organization as TOrganization, OrganizationAdmin } from '../../typings/Organization';

type State = {
  organization: TOrganization;
  admin: OrganizationAdmin;
};

export default class NewOrganization extends React.Component<any, State> {
  constructor(props) {
    super(props);

    this.state = {
      organization: {
        name: ''
      },
      admin: {
        email: '',
        firstName: '',
        lastName: ''
      }
    };
  }

  handleSubmit = async evt => {
    evt.preventDefault();

    try {
      await Organization.create(this.state.organization, this.state.admin);

      AlertStore.addAlert({
        type: 'success',
        message: 'Uusi organisaatio ja pääkäyttäjä luotu'
      });

      this.props.history.push(`/organisaatiot`);
    } catch (e) {
      console.log(e);
      AlertStore.addAlert({
        type: 'danger',
        message: 'Virhe: ' + e
      });
    }
  };

  handleOrganizationChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      organization: {
        ...prevState.organization,
        [key]: value
      }
    }));
  };

  handleAdminChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      admin: {
        ...prevState.admin,
        [key]: value
      }
    }));
  };

  isValid = () => {
    const { organization, admin } = this.state;

    if (!organization.name || !admin.email || !admin.firstName || !admin.lastName) {
      return false;
    }

    if (!admin.email.match(/^[^@]+@.+\...+$/)) {
      return false;
    }

    return true;
  };

  render() {
    return (
      <div className="container mt-5">
        <h1 className="mb-3 d-block">Lisää uusi organisaatio</h1>

        <form onSubmit={this.handleSubmit}>
          <h2 className="mb-3">Organisaation tiedot</h2>
          <InputField
            id="name"
            label="Organisaation nimi"
            type="text"
            onChange={this.handleOrganizationChange('name')}
            value={this.state.organization.name}
          />

          <h2 className="mb-3">Organisaation pääkäyttäjä</h2>

          <InputField
            id="email"
            label="Sähköposti"
            type="text"
            onChange={this.handleAdminChange('email')}
            value={this.state.admin.email || ''}
          />

          <InputField
            id="firstName"
            label="Etunimi"
            type="text"
            onChange={this.handleAdminChange('firstName')}
            value={this.state.admin.firstName}
          />

          <InputField
            id="lastName"
            label="Sukunimi"
            type="text"
            onChange={this.handleAdminChange('lastName')}
            value={this.state.admin.lastName}
          />

          <button className="btn btn-primary" disabled={!this.isValid()}>
            Lisää organisaatio
          </button>
        </form>
      </div>
    );
  }
}
