import React from 'react';
import { observer } from 'mobx-react';
import AlertStore, { Alert } from '../stores/Alert.store';

@observer
export default class Alerts extends React.Component {
  render() {
    return (
      <div className="alerts">
        {AlertStore.alerts.map((alert: Alert) => (
          <div
            onClick={() => AlertStore.clearAlert(alert.id)}
            key={alert.id}
            className={`alert alert-${alert.type} mb-1`}
            role="alert"
          >
            {alert.message}
          </div>
        ))}
      </div>
    );
  }
}
