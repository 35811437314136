import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Organization } from '../../Api';
import InputField from '../../components/InputField';
import RadioButton from '../../components/input/RadioButton';
import AlertStore from '../../stores/Alert.store';
import ModalContainer from '../ModalContainer';

interface RouteParams {
  id: string;
}

interface State {
  loading: boolean;
  saving: boolean;
  openUserEditor: boolean;
  newMember: {
    firstName: string;
    lastName: string;
    email: string;
    settings: any;
  };
  organization: {
    name: string;
    members: any;
    admins: any;
  };
}

@observer
class ViewOrganization extends React.Component<RouteComponentProps<RouteParams>, State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      openUserEditor: false,
      newMember: {
        firstName: '',
        lastName: '',
        email: '',
        settings: {}
      },
      organization: {
        name: '',
        members: [],
        admins: []
      }
    };
  }

  async componentDidMount() {
    this.loadOrganizationData();
  }

  loadOrganizationData = async () => {
    const organization = await Organization.findById(Number(this.props.match.params.id));

    this.setState({
      loading: false,
      organization: {
        name: organization.name,
        members: organization.members,
        admins: organization.admins
      }
    });
  };

  handleNewUserValueChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      newMember: {
        ...prevState.newMember,
        [key]: value
      }
    }));
  };

  handleSettingsChange = (key: string, formatNumber?: boolean) => evt => {
    const value = evt.target.value;
    this.setState(prevState => ({
      newMember: {
        ...prevState.newMember,
        settings: {
          ...prevState.newMember.settings,
          [key]: formatNumber ? Number(value) : value
        }
      }
    }));
  };

  openUserEditor = () => {
    this.setState({
      openUserEditor: true
    });
  };

  closeUserEditor = () => {
    this.setState({
      openUserEditor: false
    });
  };

  addMember = async () => {
    try {
      this.setState({
        saving: true
      });

      const member: any = await Organization.addMember(
        Number(this.props.match.params.id),
        this.state.newMember
      );

      this.setState({
        newMember: {
          firstName: '',
          lastName: '',
          email: '',
          settings: {}
        },
        saving: false,
        openUserEditor: false
      });

      AlertStore.addAlert({
        type: 'success',
        message: `${member.firstName} ${member.lastName} lisätty organisaatioon`
      });

      this.loadOrganizationData();
    } catch (e) {
      this.setState({
        openUserEditor: false
      });
      AlertStore.addAlert({
        type: 'danger',
        message: `Virhe käyttäjän lisäämisessä: ${e}`
      });
    }
  };

  render() {
    const { organization, loading, saving, newMember } = this.state;
    const user = this.state.newMember;

    const isValidMember =
      newMember.firstName.length &&
      newMember.lastName.length &&
      newMember.email.length > 6 &&
      newMember.email.indexOf('@') !== -1 &&
      newMember.settings.canViewPrices !== undefined &&
      newMember.settings.canOrderFromAnyone !== undefined &&
      newMember.settings.canEditFavourites !== undefined;

    if (loading) {
      return (
        <div className="container mt-5">
          <h2>Ladataan</h2>
        </div>
      );
    }

    return (
      <div className="container mt-5">
        <h1 className="mb-5 d-block">{organization.name}</h1>

        <button onClick={this.openUserEditor} className="btn btn-primary small mb-5 mr-3">
          Lisää uusi käyttäjä
        </button>

        <Link to={`/organisaatiot/${this.props.match.params.id}/muokkaa`}>
          <button className="btn btn-primary small mb-5">Muokkaa tietoja</button>
        </Link>

        <ModalContainer
          title="Lisää uusi käyttäjä"
          isOpen={this.state.openUserEditor}
          handleClose={this.closeUserEditor}
        >
          <div className="modal-contentx">
            <InputField
              id="firstName"
              label="Etunimi"
              type="text"
              onChange={this.handleNewUserValueChange('firstName')}
              value={user.firstName || ''}
            />
            <InputField
              id="lastName"
              label="Sukunimi"
              type="text"
              onChange={this.handleNewUserValueChange('lastName')}
              value={user.lastName || ''}
            />
            <InputField
              id="email"
              label="Sähköposti"
              type="text"
              onChange={this.handleNewUserValueChange('email')}
              value={user.email || ''}
            />

            <p>Salli kuvaajien hintojen näkeminen</p>
            <div className="radiobutton-group">
              <div className="radiobutton">
                <RadioButton
                  id="view-prices-1"
                  name="view-prices[]"
                  value="1"
                  onChange={this.handleSettingsChange('canViewPrices', true)}
                  checked={user.settings.canViewPrices === 1}
                />
                <label className="control-label" htmlFor="view-prices-1">
                  Kyllä
                </label>
              </div>
              <div className="radiobutton">
                <RadioButton
                  id="view-prices-0"
                  name="view-prices[]"
                  value="0"
                  onChange={this.handleSettingsChange('canViewPrices', true)}
                  checked={user.settings.canViewPrices === 0}
                />
                <label className="control-label" htmlFor="view-prices-0">
                  Ei
                </label>
              </div>
            </div>

            <p>Salli tilaaminen muilta, kuin yrityksen suosikeilta</p>
            <div className="radiobutton-group">
              <div className="radiobutton">
                <RadioButton
                  id="only-favs-1"
                  name="only-favs[]"
                  value="1"
                  onChange={this.handleSettingsChange('canOrderFromAnyone', true)}
                  checked={user.settings.canOrderFromAnyone === 1}
                />
                <label className="control-label" htmlFor="only-favs-1">
                  Kyllä
                </label>
              </div>
              <div className="radiobutton">
                <RadioButton
                  id="only-favs-0"
                  name="only-favs[]"
                  value="0"
                  onChange={this.handleSettingsChange('canOrderFromAnyone', true)}
                  checked={user.settings.canOrderFromAnyone === 0}
                />
                <label className="control-label" htmlFor="only-favs-0">
                  Ei
                </label>
              </div>
            </div>

            <p>Salli yrityksen suosikkilistan muokkaaminen</p>
            <div className="radiobutton-group">
              <div className="radiobutton">
                <RadioButton
                  id="edit-favs-1"
                  name="edit-favs[]"
                  value="1"
                  onChange={this.handleSettingsChange('canEditFavourites', true)}
                  checked={user.settings.canEditFavourites === 1}
                />
                <label className="control-label" htmlFor="edit-favs-1">
                  Kyllä
                </label>
              </div>
              <div className="radiobutton">
                <RadioButton
                  id="edit-favs-0"
                  name="edit-favs[]"
                  value="0"
                  onChange={this.handleSettingsChange('canEditFavourites', true)}
                  checked={user.settings.canEditFavourites === 0}
                />
                <label className="control-label" htmlFor="edit-favs-0">
                  Ei
                </label>
              </div>
            </div>

            <div className="text-center">
              <button
                onClick={this.addMember}
                disabled={!isValidMember || saving}
                className="btn btn-primary small mb-5"
              >
                Lisää uusi käyttäjä
              </button>
            </div>
          </div>
        </ModalContainer>

        <div className="row">
          <div className="col-12 col-md-6">
            <h2 className="mb-3">Pääkäyttäjät</h2>

            <ul className="user-list mb-3">
              {organization.admins.map(u => (
                <Link key={u.id} to={`/kayttajat/${u.id}`}>
                  <li className="user-list-item">
                    <div>
                      <strong>{`${u.firstName} ${u.lastName}`}</strong>
                    </div>
                    <div>
                      <div className="d-block d-sm-inline mr-2">
                        <span className="user-list-item-label">Email: </span>
                        {u.email}
                      </div>
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="col-12 col-md-6">
            <h2 className="mb-3">Käyttäjät</h2>

            <ul className="user-list mb-3">
              {organization.members.map(u => (
                <Link key={u.id} to={`/kayttajat/${u.id}`}>
                  <li className="user-list-item">
                    <div>
                      <strong>{`${u.firstName} ${u.lastName}`}</strong>
                    </div>
                    <div>
                      <div className="d-block d-sm-inline mr-2">
                        <span className="user-list-item-label">Email: </span>
                        {u.email}
                      </div>
                      {!u.active && (
                        <div className="d-block d-sm-inline mr-2">
                          <span className="text-danger">Ei aktivoitu</span>
                        </div>
                      )}
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ViewOrganization);
