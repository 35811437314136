import React from 'react';

const Footer = () => {
  return (
    <div className="footer mt-3">
      <div className="container">
        <div className="row">
          <div className="col-12" style={{ fontSize: 12, paddingTop: 80, paddingBottom: 20 }}>
            &copy; 2021 Bondr Oy &nbsp; | &nbsp;
            <a href="https://bondr.fi/" rel="noopener noreferrer" target="_blank">
              Käyttöehdot
            </a>
            &nbsp; | &nbsp;
            <a href="https://bondr.fi/privacy-policy/" rel="noopener noreferrer" target="_blank">
              Tietosuojaseloste
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
