import React from 'react';
import { withRouter } from 'react-router-dom';
import TextArea from '../../components/TextArea';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

class NewVirtualStyling extends React.Component<any, any> {
  handleImageUpload = () => {};

  render() {
    const comingSoon = true;

    if (comingSoon) {
      return (
        <div className="container pt-5">
          <h1 className="mb-5">Omat virtuaalistailaukset</h1>

          <p>Tulossa pian</p>
        </div>
      );
    }

    return (
      <div className="container pt-5">
        <h1 className="mb-5">Uusi virtuaalistailaus</h1>

        <div className="form-group">
          <h2>Valitse kalustettava huone</h2>
          <select className="form-control">
            <option />
            <option value="0">Keittiö</option>
            <option value="1">Olohuone</option>
            <option value="2">Makuuhuone</option>
            <option value="3">Eteinen</option>
            <option value="4">Kylpyhuone</option>
            <option value="5">WC</option>
            <option value="6">Sauna</option>
            <option value="7">Joku muu</option>
            ))
          </select>
        </div>

        <div className="form-group">
          <h2>Kalustuksen tyyli</h2>
          <select className="form-control">
            <option />
            <option value="0">Skandinaavinen</option>
            <option value="1">Industrial</option>
            <option value="2">Moderni</option>
            ))
          </select>
        </div>

        <div>
          <h2>Referenssikuvat</h2>
          <Dropzone
            accept="image/jpg, image/jpeg, image/png"
            minSize={0}
            maxSize={5}
            onDrop={this.handleImageUpload}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles }) => {
              return (
                <section className={classNames('dropzone mt-3 mb-3', { active: isDragActive })}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span>Klikkaa tai pudota tiedosto tähän</span>
                    {isDragReject && (
                      <span className="d-block error">
                        Virhe: Kuvan täytyy olla jpg- tai png-muodossa
                      </span>
                    )}
                  </div>
                </section>
              );
            }}
          </Dropzone>
        </div>

        <h2 className="mb-3">Lisätiedot ja kommentit</h2>

        <TextArea id="additionalinfo" label="Lisätiedot palveluntuottajalle" onChange={evt => {}} />

        <div className="text-center">
          <button className="btn btn-primary">Vahvista tilaus</button>
        </div>
      </div>
    );
  }
}

export default withRouter(NewVirtualStyling);
